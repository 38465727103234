import serviceAxios from '../index'
// get 请求需要传 params，post 请求需要传 data
// 获取牌匾信息、公司信息
export const companyInfo = (data) => {
  return serviceAxios({
    url: '/api/index/get_company',
    method: 'post',
    data
  })
}
// 保存地址
export const addressSave = (data) => {
  return serviceAxios({
    url: '/api/index/saveAddress',
    method: 'post',
    data
  })
}
// 支付宝支付
export const zhifubao = (data) => {
  return serviceAxios({
    url: '/api/index/alipay',
    method: 'post',
    data
  })
}
// 微信外置支付
export const weixinWai = (data) => {
  return serviceAxios({
    url: '/api/index/h5_pay',
    method: 'post',
    data
  })
}
// 微信内置支付
export const weixinei = (data) => {
  return serviceAxios({
    url: '/api/index/wx_pay',
    method: 'post',
    data
  })
}
// 获取openid
export const getopenid = (data) => {
  return serviceAxios({
    url: '/api/index/get_openid',
    method: 'post',
    data
  })
}
// 订单查询
export const chaxun = (data) => {
  return serviceAxios({
    url: '/api/index/get_order',
    method: 'post',
    data
  })
}
