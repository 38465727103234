import axios from 'axios'
import serverConfig from './config'
import { Toast } from 'vant'

// 创建 axios 请求实例
const serviceAxios = axios.create({
  baseURL: serverConfig.baseURL, // 基础请求地址
  timeout: 10000 // 请求超时设置
})

// 创建请求拦截
serviceAxios.interceptors.request.use(
  (config) => {
    Toast.loading({
      duration: 0, // 持续展示 toast
      forbidClick: true,
      message: '加载中...'
    })
    return config
  },
  (error) => {
    alert('请求时发生错误')
    return Promise.reject(error)
  }
)

// 创建响应拦截
serviceAxios.interceptors.response.use(
  (res) => {
    const data = res.data
    Toast.clear()
    return data
  },
  (error) => {
    return Promise.reject(error)
  }
)
export default serviceAxios
