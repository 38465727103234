<!--
  作者：顾先生
-->
<template>
  <div id="zhifu">
    <div class="zhifuBox">
      <img src="../../assets/indexImg/topBgc.png" class="zhifuBox_Img">
      <!-- LOGO-->
      <div class="logoBox">
        <!-- 符合加入-->
        <div class="ChengLI clearfix">
          <div class="gengduoyiBox_p1"><div class="henggang henggang1"></div>关于守信激励惠企服务包<div class="henggang henggang2"></div></div>
          <div class="gengduoyiBox_p2">惠企服务包是守信激励计划将企业日常经营所需要的基础服务打包起来，实现综合价格比市场更低来降低中小企业的日常经营成本支出，为守信企业减负增效。</div>
        </div>
      </div>
      <!--    牌匾支付区域-->
      <div class="paibianZhiFu publicOne" id="gundong">
        <div class="paibianZhiFuBox">
          <p class="paibianZhiFu_p1"><img src="../../assets/ZhiFuImg/liwu.png" class="liwu1"><strong>支付加入计划，获得惠企服务包</strong><img src="../../assets/ZhiFuImg/liwu.png" class="liwu2"></p>
          <!--    牌匾图区域-->
          <div class="paibianImg" id="gundongFuWu">
            <img :src="paibianImg" class="paibianImg1">
            <!-- 公司名称-->
            <div :style="{'opacity' : fuwubao4 != true ? '1' : '0'}" :class="[gao == true ? 'companyzuhe' : dijiaOne == true ? 'companyTong' : 'companyMu']">
              <div ref="companyzuhe" class="autoCompanyZuhe">
                <span class="autoCompanyZuhe_span">{{ companyName }}</span>
              </div>
            </div>
            <!-- 公司名称-->
            <div :style="{'opacity' : fuwubao4 == true ? '1' : '0'}" :class="[gao == true ? 'companyBoxFuWu' : dijiaOne == true ? 'companyTongFuWu' : 'companyMuFuWu']">
              <div ref="companyzuheThree" class="autoCompanyZuhe">
                <span class="autoCompanyZuhe_span">{{ companyName }}</span>
              </div>
            </div>
            <!-- 公司名称-->
            <div :style="{'opacity' : fuwubao4 == true ? '1' : '0'}" :class="[gao == true ? 'companyMuZuHeFuWu' : 'companyFuWu']">
              <div ref="companyzuheTwo" class="autoCompanyZuhe">
                <span class="autoCompanyZuhe_span">{{ companyName }}</span>
              </div>
            </div>
          </div>
          <!--    选择价格  点击事件-->
          <div class="paibianJiage">
            <p class="paibianJiage_p1">请选择适合企业当前发展需要的服务</p>
            <!--    会蠕动的小手-->
            <img src="../../assets/ZhiFuImg/shou.png" class="shou1 my_xing" ref="applyForUrl" v-show="!FuWuBaoZong">
            <!--    服务包-->
            <div v-show="fuwubaoFirst1" :class="['jiage1FuWuBao', fuwubao1 == true ? 'jiageFuWuBaoActive' : '']" @click="jiage1FuWuBao">
              <div :class="['jiage1_leftFuWuBao', fuwubao1 == true ? 'jiage1_leftFuWuBaoAvtive' : '']">
                <div :class="['jiage1_left_topFuWuBao', fuwubao1 == true ? 'jiage1_left_topFuWuBaoActive' : '']">惠企服务包一</div>
              </div>
              <div class="jiage1_rightFuWuBao">
                <div :class="['jiage1_left_top1FuWuBao', fuwubao1 == true ? 'jiage1_left_top1FuWuBaoActive' : '']">荣誉牌匾+法律咨询+商标代办</div>
                <div class="jiage1_left_bottom1FuWuBao">综合三种服务，有效解决企业日常经营问题</div>
              </div>
            </div>
            <div v-show="fuwubaoFirst2" :class="['jiage1FuWuBao', fuwubao2 == true ? 'jiageFuWuBaoActive' : '']" @click="jiage2FuWuBao">
              <div :class="['jiage1_leftFuWuBao', fuwubao2 == true ? 'jiage1_leftFuWuBaoAvtive' : '']">
                <div :class="['jiage1_left_topFuWuBao', fuwubao2 == true ? 'jiage1_left_topFuWuBaoActive' : '']">惠企服务包二</div>
              </div>
              <div class="jiage1_rightFuWuBao">
                <div :class="['jiage1_left_top1FuWuBao', fuwubao2 == true ? 'jiage1_left_top1FuWuBaoActive' : '']">荣誉牌匾+法律咨询</div>
                <div class="jiage1_left_bottom1FuWuBao">让信用更有价值+解决日常经营的法律疑惑</div>
              </div>
            </div>
            <div v-show="fuwubaoFirst3" :class="['jiage1FuWuBao', fuwubao3 == true ? 'jiageFuWuBaoActive' : '']" @click="jiage3FuWuBao">
              <div :class="['jiage1_leftFuWuBao', fuwubao3 == true ? 'jiage1_leftFuWuBaoAvtive' : '']">
                <div :class="['jiage1_left_topFuWuBao', fuwubao3 == true ? 'jiage1_left_topFuWuBaoActive' : '']">惠企服务包三</div>
              </div>
              <div class="jiage1_rightFuWuBao">
                <div :class="['jiage1_left_top1FuWuBao', fuwubao3 == true ? 'jiage1_left_top1FuWuBaoActive' : '']">荣誉牌匾+商标代办</div>
                <div class="jiage1_left_bottom1FuWuBao">让信用更有价值+解决注册商标不专业问题</div>
              </div>
            </div>
            <div v-show="fuwubaoFirst4" :class="['jiage1FuWuBao', fuwubao4 == true ? 'jiageFuWuBaoActive' : '']" @click="jiage4FuWuBao">
              <div :class="['jiage1_leftFuWuBao', fuwubao4 == true ? 'jiage1_leftFuWuBaoAvtive' : '']">
                <div :class="['jiage1_left_topFuWuBao', fuwubao4 == true ? 'jiage1_left_topFuWuBaoActive' : '']">惠企服务包四</div>
              </div>
              <div class="jiage1_rightFuWuBao">
                <div :class="['jiage1_left_top1FuWuBao', fuwubao4 == true ? 'jiage1_left_top1FuWuBaoActive' : '']">金属荣誉牌匾+木质荣誉牌匾</div>
                <div class="jiage1_left_bottom1FuWuBao">让上门客户更容易了解企业信用</div>
              </div>
            </div>
            <!--    惠企服务包一-->
            <div v-show="fuwubaoTwo1" class="HuiQiBox1">
              <div :class="['jiage1', gao == true ? 'jiageActive' : '']" @click="jiage1(788)">
                <div :class="['jiage1_left', gao == true ? 'jiage1_leftAvtive' : '']">
                  <div :class="['jiage1_left_top', gao == true ? 'jiage1_left_topActive' : '']">788元</div>
                  <div class="jiage1_left_bottom">3698/年</div>
                </div>
                <div class="jiage1_right">
                  <div :class="['jiage1_left_top1', gao == true ? 'jiage1_left_top1Active' : '']">组合牌匾+法律咨询+商标代办</div>
                  <div class="jiage1_left_bottom1">498元/一铜一木+2000元/包+1200元/一标一类</div>
                </div>
              </div>
              <div :class="['jiage1', dijiaOne == true ? 'jiageActive' : '']" @click="jiage2(688)">
                <div :class="['jiage1_left', dijiaOne == true ? 'jiage1_leftAvtive' : '']">
                  <div :class="['jiage1_left_top', dijiaOne == true ? 'jiage1_left_topActive' : '']">688元</div>
                  <div class="jiage1_left_bottom">3498/年</div>
                </div>
                <div class="jiage1_right">
                  <div :class="['jiage1_left_top1', dijiaOne == true ? 'jiage1_left_top1Active' : '']">金属牌匾+法律咨询+商标代办</div>
                  <div class="jiage1_left_bottom1">298元/一铜+2000元/包+1200元/一标一类</div>
                </div>
              </div>
              <div :class="['jiage1', dijiaTwo == true ? 'jiageActive' : '']" @click="jiage3(588)">
                <div :class="['jiage1_left', dijiaTwo == true ? 'jiage1_leftAvtive' : '']">
                  <div :class="['jiage1_left_top', dijiaTwo == true ? 'jiage1_left_topActive' : '']">588元</div>
                  <div class="jiage1_left_bottom">3398/年</div>
                </div>
                <div class="jiage1_right">
                  <div :class="['jiage1_left_top1', dijiaTwo == true ? 'jiage1_left_top1Active' : '']">木质牌匾+法律咨询+商标代办</div>
                  <div class="jiage1_left_bottom1">198元/一木+2000元/包+1200元/一标一类</div>
                </div>
              </div>
            </div>
            <!--    惠企服务包二-->
            <div v-show="fuwubaoTwo2" class="HuiQiBox2">
              <div :class="['jiage1', gao == true ? 'jiageActive' : '']" @click="jiage1(618)">
                <div :class="['jiage1_left', gao == true ? 'jiage1_leftAvtive' : '']">
                  <div :class="['jiage1_left_top', gao == true ? 'jiage1_left_topActive' : '']">618元</div>
                  <div class="jiage1_left_bottom">2498元/年</div>
                </div>
                <div class="jiage1_right">
                  <div :class="['jiage1_left_top1', gao == true ? 'jiage1_left_top1Active' : '']">组合牌匾+法律咨询</div>
                  <div class="jiage1_left_bottom1">498元/一铜一木+2000元/包</div>
                </div>
              </div>
              <div :class="['jiage1', dijiaOne == true ? 'jiageActive' : '']" @click="jiage2(518)">
                <div :class="['jiage1_left', dijiaOne == true ? 'jiage1_leftAvtive' : '']">
                  <div :class="['jiage1_left_top', dijiaOne == true ? 'jiage1_left_topActive' : '']">518元</div>
                  <div class="jiage1_left_bottom">2298/年</div>
                </div>
                <div class="jiage1_right">
                  <div :class="['jiage1_left_top1', dijiaOne == true ? 'jiage1_left_top1Active' : '']">金属牌匾+法律咨询</div>
                  <div class="jiage1_left_bottom1">298元/一铜+2000元/包</div>
                </div>
              </div>
              <div :class="['jiage1', dijiaTwo == true ? 'jiageActive' : '']" @click="jiage3(460)">
                <div :class="['jiage1_left', dijiaTwo == true ? 'jiage1_leftAvtive' : '']">
                  <div :class="['jiage1_left_top', dijiaTwo == true ? 'jiage1_left_topActive' : '']">460元</div>
                  <div class="jiage1_left_bottom">2198元/年</div>
                </div>
                <div class="jiage1_right">
                  <div :class="['jiage1_left_top1', dijiaTwo == true ? 'jiage1_left_top1Active' : '']">木质牌匾+法律资询</div>
                  <div class="jiage1_left_bottom1">198元/一木+2000元/包</div>
                </div>
              </div>
            </div>
            <!--    惠企服务包三-->
            <div v-show="fuwubaoTwo3" class="HuiQiBox3">
              <div :class="['jiage1', gao == true ? 'jiageActive' : '']" @click="jiage1(588)">
                <div :class="['jiage1_left', gao == true ? 'jiage1_leftAvtive' : '']">
                  <div :class="['jiage1_left_top', gao == true ? 'jiage1_left_topActive' : '']">588元</div>
                  <div class="jiage1_left_bottom">1698元/年</div>
                </div>
                <div class="jiage1_right">
                  <div :class="['jiage1_left_top1', gao == true ? 'jiage1_left_top1Active' : '']">组合牌匾+商标代办</div>
                  <div class="jiage1_left_bottom1">498元/一铜一木+1200元/一标一类</div>
                </div>
              </div>
              <div :class="['jiage1', dijiaOne == true ? 'jiageActive' : '']" @click="jiage2(488)">
                <div :class="['jiage1_left', dijiaOne == true ? 'jiage1_leftAvtive' : '']">
                  <div :class="['jiage1_left_top', dijiaOne == true ? 'jiage1_left_topActive' : '']">488元</div>
                  <div class="jiage1_left_bottom">1498元/年</div>
                </div>
                <div class="jiage1_right">
                  <div :class="['jiage1_left_top1', dijiaOne == true ? 'jiage1_left_top1Active' : '']">金属牌匾+商标代办</div>
                  <div class="jiage1_left_bottom1">298元/一铜+1200元/一标一类</div>
                </div>
              </div>
              <div :class="['jiage1', dijiaTwo == true ? 'jiageActive' : '']" @click="jiage3(430)">
                <div :class="['jiage1_left', dijiaTwo == true ? 'jiage1_leftAvtive' : '']">
                  <div :class="['jiage1_left_top', dijiaTwo == true ? 'jiage1_left_topActive' : '']">430元</div>
                  <div class="jiage1_left_bottom">1398元/年</div>
                </div>
                <div class="jiage1_right">
                  <div :class="['jiage1_left_top1', dijiaTwo == true ? 'jiage1_left_top1Active' : '']">木质牌匾+商标代办</div>
                  <div class="jiage1_left_bottom1">198元/一木+1200元/一标一类</div>
                </div>
              </div>
            </div>
            <!--    惠企服务包四-->
            <div v-show="fuwubaoTwo4" class="HuiQiBox4">
              <div :class="['jiage1', gao == true ? 'jiageActive' : '']" @click="jiage1(288)">
                <div :class="['jiage1_left', gao == true ? 'jiage1_leftAvtive' : '']">
                  <div :class="['jiage1_left_top', gao == true ? 'jiage1_left_topActive' : '']">288元</div>
                  <div class="jiage1_left_bottom">496元/年</div>
                </div>
                <div class="jiage1_right">
                  <div :class="['jiage1_left_top1', gao == true ? 'jiage1_left_top1Active' : '']">金属牌匾+木质牌匾</div>
                  <div class="jiage1_left_bottom1">298元/一铜+198元/一木</div>
                </div>
              </div>
              <div :class="['jiage1', dijiaOne == true ? 'jiageActive' : '']" @click="jiage2(188)">
                <div :class="['jiage1_left', dijiaOne == true ? 'jiage1_leftAvtive' : '']">
                  <div :class="['jiage1_left_top', dijiaOne == true ? 'jiage1_left_topActive' : '']">188元</div>
                  <div class="jiage1_left_bottom">298元/年</div>
                </div>
                <div class="jiage1_right">
                  <div :class="['jiage1_left_top1', dijiaOne == true ? 'jiage1_left_top1Active' : '']">金属牌匾（可悬挂大门）</div>
                  <div class="jiage1_left_bottom1">298元/一铜（尺寸：60*40cm）</div>
                </div>
              </div>
              <div :class="['jiage1', dijiaTwo == true ? 'jiageActive' : '']" @click="jiage3(130)">
                <div :class="['jiage1_left', dijiaTwo == true ? 'jiage1_leftAvtive' : '']">
                  <div :class="['jiage1_left_top', dijiaTwo == true ? 'jiage1_left_topActive' : '']">130元</div>
                  <div class="jiage1_left_bottom">198元/年</div>
                </div>
                <div class="jiage1_right">
                  <div :class="['jiage1_left_top1', dijiaTwo == true ? 'jiage1_left_top1Active' : '']">木质牌匾（可摆放办公室）</div>
                  <div class="jiage1_left_bottom1">198元/一木（尺寸：40*30cm）</div>
                </div>
              </div>
            </div>
            <!--    微信支付、支付宝-->
            <div class="WeChatAndZfb">
              <van-radio-group v-model="radio" direction="horizontal" icon-size="20px" checked-color="#dd2424" class="paymentRadio">
                <div class="wechatImg" style="margin-right: 20px">
                  <img src="../../assets/ZhiFuImg/weixin.png" class="wechatImg_img">
                  <van-radio name="1" label-position="left">微信支付</van-radio>
                </div>
                <div class="wechatImg">
                  <img src="../../assets/ZhiFuImg/zhifubao.png" class="wechatImg_img">
                  <van-radio name="2" label-position="left">支付宝</van-radio>
                </div>
              </van-radio-group>
            </div>
            <!--      立即去支付-->
            <van-button class="ZhiFuBtn radiuPublic" @click="zhifu">
              <span class="ZhiFuBtn_span1">支付获取服务</span>
              <span class="ZhiFuBtn_span2">支付后可联系客服开票</span>
            </van-button>
            <!--      阅读协议-->
            <div class="yuedu">请阅读<span style="color:#205fff;" @click="SXfuwushuoMing = true">《守信激励计划服务说明》</span>及<span style="color:#205fff;" @click="YSshuoMing = true">《隐私说明》</span>后支付</div>
          </div>
          <div class="jiage1FuWuBaoFont jiage1FuWuBaoFontDong" v-show="FuWuBaoZong">如不适合，还有三个服务包可选择</div>
          <div class="FuWuBaoZong" v-show="FuWuBaoZong">
            <div v-show="!fuwubao1" :class="['jiage2FuWuBao', fuwubao1 == true ? 'jiageFuWuBaoActive' : '']" @click="jiage1FuWuBao">
              <div :class="['jiage1_leftFuWuBao', fuwubao1 == true ? 'jiage1_leftFuWuBaoAvtive' : '']">
                <div :class="['jiage1_left_topFuWuBao', fuwubao1 == true ? 'jiage1_left_topFuWuBaoActive' : '']">惠企服务包一</div>
              </div>
              <div class="jiage1_rightFuWuBao">
                <div :class="['jiage1_left_top1FuWuBao', fuwubao1 == true ? 'jiage1_left_top1FuWuBaoActive' : '']">荣誉牌匾+法律咨询+商标代办</div>
                <div class="jiage1_left_bottom1FuWuBao">综合三种服务，有效解决企业日常经营问题</div>
              </div>
            </div>
            <div v-show="!fuwubao2" :class="['jiage2FuWuBao', fuwubao2 == true ? 'jiageFuWuBaoActive' : '']" @click="jiage2FuWuBao">
              <div :class="['jiage1_leftFuWuBao', fuwubao2 == true ? 'jiage1_leftFuWuBaoAvtive' : '']">
                <div :class="['jiage1_left_topFuWuBao', fuwubao2 == true ? 'jiage1_left_topFuWuBaoActive' : '']">惠企服务包二</div>
              </div>
              <div class="jiage1_rightFuWuBao">
                <div :class="['jiage1_left_top1FuWuBao', fuwubao2 == true ? 'jiage1_left_top1FuWuBaoActive' : '']">荣誉牌匾+法律咨询</div>
                <div class="jiage1_left_bottom1FuWuBao">让信用更有价值+解决日常经营的法律疑惑</div>
              </div>
            </div>
            <div v-show="!fuwubao3" :class="['jiage2FuWuBao', fuwubao3 == true ? 'jiageFuWuBaoActive' : '']" @click="jiage3FuWuBao">
              <div :class="['jiage1_leftFuWuBao', fuwubao3 == true ? 'jiage1_leftFuWuBaoAvtive' : '']">
                <div :class="['jiage1_left_topFuWuBao', fuwubao3 == true ? 'jiage1_left_topFuWuBaoActive' : '']">惠企服务包三</div>
              </div>
              <div class="jiage1_rightFuWuBao">
                <div :class="['jiage1_left_top1FuWuBao', fuwubao3 == true ? 'jiage1_left_top1FuWuBaoActive' : '']">荣誉牌匾+商标代办</div>
                <div class="jiage1_left_bottom1FuWuBao">让信用更有价值+解决注册商标不专业问题</div>
              </div>
            </div>
            <div v-show="!fuwubao4" :class="['jiage2FuWuBao', fuwubao4 == true ? 'jiageFuWuBaoActive' : '']" @click="jiage4FuWuBao">
              <div :class="['jiage1_leftFuWuBao', fuwubao4 == true ? 'jiage1_leftFuWuBaoAvtive' : '']">
                <div :class="['jiage1_left_topFuWuBao', fuwubao4 == true ? 'jiage1_left_topFuWuBaoActive' : '']">惠企服务包四</div>
              </div>
              <div class="jiage1_rightFuWuBao">
                <div :class="['jiage1_left_top1FuWuBao', fuwubao4 == true ? 'jiage1_left_top1FuWuBaoActive' : '']">金属荣誉牌匾+木质荣誉牌匾</div>
                <div class="jiage1_left_bottom1FuWuBao">让上门客户更容易了解企业信用</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 售前咨询-->
      <div class="ZiXunBox publicOne">
        <div class="ZiXunBoxContent">
          <img src="../../assets/ZhiFuImg/zixun.png" class="ZiXunBoxContent_img">
          <div class="ZiXunBoxContentBox">
            <img src="../../assets/indexImg/boZixun.png" class="ZiXunBoxContentBox_left" @click="boZixun">
            <img src="../../assets/indexImg/weiZixun.png" class="ZiXunBoxContentBox_right" @click="weiZixun">
          </div>
        </div>
      </div>
      <!--    地址-->
      <div class="addressXiu publicOne">
        <div class="addressXiuBoxBox">
          <div class="addressXiuBox radiuPublic">
            <div class="topText radiuPublic">请认真核查并填写收货地址</div>
            <div class="textBox">
              <div class="textBox_left">
                <span class="textBox_left_p1">寄送地址</span>
                <span class="textBox_left_p2">快递包邮送达</span>
              </div>
              <div class="textBox_right" @click="addressUpdate">修改地址</div>
            </div>
            <p class="text_p1">{{ AddressInfo.province }} {{ AddressInfo.city }} {{ AddressInfo.county }}{{ AddressInfo.addressDetail }}</p>
            <p class="text_p1">{{ AddressInfo.name }} {{ AddressInfo.tel }}</p>
          </div>
        </div>
      </div>
      <!--    计划保障-->
      <div class="baozhang publicOne">
        <div class="baozhangBox">
          <div class="baozhangBox1">
            <img src="../../assets/ZhiFuImg/hong.png" class="baozhangBox1_img">
            <p class="baozhangBox1_p">法律咨询服务说明</p>
          </div>
          <p class="baozhangBox1_p2">惠企服务包中的“企业法律咨询服务”包含:</p>
          <p class="baozhangBox1_p1">1、常规法律问题咨询，每年最高可咨询5次；</p>
          <p class="baozhangBox1_p1">2、法律文书代写服务，每年最高提供5次代写服务；</p>
          <p class="baozhangBox1_p1">3、债权管理问题咨询，每年仅可咨询1次。</p>
          <p class="baozhangBox1_p3">基础服务包旨在为企业解决日常经营中遇到的基础法律问题，如果对应服务次数用完，可有偿持续为企业提供。</p>
        </div>
      </div>
      <!--    计划保障-->
      <div class="baozhang publicOne">
        <div class="baozhangBox">
          <div class="baozhangBox1">
            <img src="../../assets/ZhiFuImg/lan.png" class="baozhangBox1_img">
            <p class="baozhangBox1_plan">商标注册代办说明</p>
          </div>
          <p class="baozhangBox1_p2lan">惠企服务包中的“企业商标注册代办服务”为客户准备了每年最高一个的<span style="color: #1b8ae0">“一个商标一个类别”</span>服务（根据商标局的划分，一个商标由于类别、用处不同，可<span style="color: #1b8ae0">共计分为45个类别</span>）客户可根据实际需要挑选其中的一个类别</p>
          <p class="baozhangBox1_p3lan">如果客户同一个商标想申请多个类别或者申请不同的商标，我们会持续提供比市场价更低的有偿服务。</p>
        </div>
      </div>
      <!--    服务说明-->
      <div class="fuwushuomingBox publicOne">
        <div class="fuwushuomingBoxBox">
          <p class="fuwuText radiuPublic">服务保障与其他说明</p>
          <div class="fuwuArrBox publicFont" v-for="(item,index) in fuwuArrBox" :key="index">
            <div class="fuwuBoxText">
              <div class="fuwuBoxTextTop">
                <div class="fuwuBoxTextTop_left"><img :src="item.nums" class="fuwuBoxTextTop_left_img"></div>
                <div class="fuwuBoxTextTop_right">{{ item.biaotititle }}</div>
              </div>
            </div>
            <p class="fuwuBoxText_p1">{{ item.neirongtext1 }}</p>
            <p class="fuwuBoxText_p1">{{ item.neirongtext2 }}</p>
            <p class="fuwuBoxText_p1">{{ item.neirongtext3 }}</p>
            <p class="fuwuBoxText_p1">{{ item.neirongtext4 }}</p>
            <p class="fuwuBoxText_p1">{{ item.neirongtext5 }}</p>
          </div>
        </div>
      </div>
    </div>
    <!--    底部-->
    <bottom></bottom>
    <!--    地址弹出-->
    <van-overlay :show="Addressshow" @click="AddressshowClose">
      <div class="addressBox" v-on:click.stop>
        <p class="addressBox_p1">请确认您的收货地址</p>
        <van-address-edit
          :area-list="areaList"
          :address-info="AddressInfo"
          :area-columns-placeholder="['请选择', '请选择', '请选择']"
          save-button-text="立即提交"
          :is-saving = "submitaddressBtn"
          @save="submitAddress"
        />
      </div>
    </van-overlay>
    <!--    守信企业计划服务说明-->
    <van-dialog
      v-model="SXfuwushuoMing"
      confirm-button-color="#0075ff"
      confirm-button-text="已阅读"
      message-align="left"
      message="<span style='text-indent: 2em; text-align:justify;display: inline-block;line-height: 6vw;'>为响应党和国家“守信激励、失信惩戒”及社会信用体系建设，让中小企业（含个体经营者，以下统称“经营主体或主体”更好发展，在经营过程中企业信用更有价值及享受“守信激励”惠企服务。中雄世纪征信有限公司（以下简称“中雄征信”）通过“守信激励计划”向经营主体推出有意愿建立守信经营形象为前提的信用展示和惠企服务包服务。为维护各方权益，现就守信激励计划的有关服务进行告知与说明。企业在支付守信激励计划服务相关费用后，表明其已阅读本说明并同意依照本说明办理服务、处理服务纠纷。</span>
      <p style='margin-top : 5vw;'>一、关于守信激励计划</p>
      <span style='text-indent: 1.5em; text-align:justify;display: inline-block;line-height: 6vw;'>“守信激励计划”是由第三方信用机构（中雄世纪征信有限公司）发起，主旨是为有意愿守信经营的中小企业提升企业信用形象，为激励守信意愿提供更多惠企服务的计划，降低中小企业经营成本。</span>
      <p style='margin-top : 5vw;'>二、守信激励计划服务说明</p>
      <span style='text-indent: 1.5em; text-align:justify;display: inline-block;line-height: 6vw;'>1、守信激励计划主要是帮助有意愿建立守信经营形象的经营主体展示守信经营形象并享受在“守信经营意愿”前提下，中雄征信提供的守信激励惠企服务包服务，所以在加入计划之前经营主体会首先签署守信激励计划“守信承诺书”，表达其守信经营意愿；</span>
      <span style='text-indent: 1.5em; text-align:justify;display: inline-block;line-height: 6vw;'>2、守信激励计划牌匾作为信用展示服务的一个载体，主要有守信承诺经营者牌匾、守信经营者牌匾、守信示范经营者牌匾及其他荣誉牌匾，主体可就其当前经营需要选择适合其运营的种类（可购买多种展示服务）；</span>
      <span style='text-indent: 1.5em; text-align:justify;display: inline-block;line-height: 6vw;'>3、电子证书、信用官网公示、电子信用档案信用展示服务，主要是帮助企业展示守信经营理念，电子证书是消费者扫描牌匾二维码产生，方便消费者核验经营主体的企业信用，官网公示是指在“守信企业计划”网站公示企业加入的日期等信息，让消费者可查询并可监督，电子信用档案是在“中雄征信”搭建的企业信用信息查询平台为经营主体生成一份除基本工商信息外，还有其加入守信激励计划时购买的信用展示服务类型。信用展示服务主旨是为了帮助经营主体建立守信经营形象，方便消费者和中雄征信一起监督、监测；</span>
      <span style='text-indent: 1.5em; text-align:justify;display: inline-block;line-height: 6vw;'>4、信用展示服务产生和牌匾的制作与邮寄，意味着经营主体在当前申请时不存在严重失信行为、签署了守信承诺书、愿意接受社会的监督、愿意为国家信用体系建设贡献力量；</span>
      <span style='text-indent: 1.5em; text-align:justify;display: inline-block;line-height: 6vw;'>5、中雄征信会持续对加入守信激励计划的主体企业信用进行动态监测，如果在服务期间，经营主体有失信行为的，包括但不限于失信被执行、因重大违法行为被行政处罚、重大税务违法、企业注销、重大负面舆情等，公司将终止服务并不退还守信激励计划已提供的信用审核和实体牌匾制作、邮寄、信用展示、信用信息维护等服务费用；</span>
      <span style='text-indent: 1.5em; text-align:justify;display: inline-block;line-height: 6vw;'>6、守信激励惠企服务包说明，经营主体在加入守信激励计划后，会选择适合其当前企业当前经营所需要的服务，惠企服务包提供了基础“企业法律咨询”服务和“商标注册代办”服务，其中“企业法律咨询”服务包含“ 常规法律咨询（服务有效期内，可最高咨询5次）、代写法律文书（服务有效期内，可最高代写5次）、债权管理咨询（服务有效期内，可最高咨询1次）”、“商标注册代办”服务包含“一标一类（根据商标局划分，一个商标共分为45个类别，享受服务后，经营主体可选择一个商标45个类别中的一个让中雄征信注册代办）的商标注册代办服务”。以上惠企服务包所提供的咨询和代办服务，如果经营主体在享受对应服务后，仍需要更多次数的服务，中雄征信会持续提供有偿的服务并承诺有偿服务费用比市场价格更低。</span>
      <p style='margin-top : 5vw;'>三、守信激励计划加入条件</p>
      <span style='text-indent: 0em; text-align:justify;display: inline-block;line-height: 6vw;'>申请加入守信激励计划必须满足以下条件：</span>
      <span style='text-indent: 1.5em; text-align:justify;display: inline-block;line-height: 6vw;'>1、依法在中国大陆注册的企业并且有意愿守信经营；</span>
      <span style='text-indent: 1.5em; text-align:justify;display: inline-block;line-height: 6vw;'>2、成立至申请之日止不存在以下失信行为：失信被执行人记录、被执行人记录、近两年内存在行政处罚记录、存在重大税务违法记录、重大负面舆情记录、等失信行为；</span>
      <span style='text-indent: 1.5em; text-align:justify;display: inline-block;line-height: 6vw;'>3、遵守本说明约定并按本说明履行责任和义务。</span>
      <p style='margin-top : 5vw;'>四、守信激励计划服务收费参考（实际价格以企业需求为准）</p>
      <span style='text-indent: 1.5em; text-align:justify;display: inline-block;line-height: 6vw;'>1、守信激励计划提供的惠企服务包按照最低一年288元收费（包含实体荣誉铜牌（尺寸60cm*40cm）+实体荣誉木牌（尺寸40cm*30cm）+信用展示服务+服务期间企业信用信息维护+实体荣誉牌匾的制作与邮寄）；</span>
      <span style='text-indent: 1.5em; text-align:justify;display: inline-block;line-height: 6vw;'>2、基础服务包以外，如果经营主体需要增加法律咨询服务和商标注册代办服务，以组合的方式来增加服务费用，基础服务包+法律咨询服务或基础服务包+商标注册代办服务，需要增加最低300元/年享受增加服务，还可以基础服务包+法律咨询服务+商标注册代办服务的组合形式，这种情况下需要增加最低500元/年享受增加服务，如果超出守信激励惠企服务包提供的相关最高次数服务后，经营主体如果还持续需要对应服务，中雄征信会持续有偿提供服务；</span>
      <span style='text-indent: 1.5em; text-align:justify;display: inline-block;line-height: 6vw;'>3、次年服务到期前中雄征信会联系经营主体，如还需要继续办理服务，中雄征信会对经营主体信用情况进行审核，符合标准可继续办理，如过期不办理，守信激励计划提供的惠企服务包服务将全部失效；</span>
      <p style='margin-top : 5vw;'>五、其他相关说明</p>
      <span style='text-indent: 1.5em; text-align:justify;display: inline-block;line-height: 6vw;'>1、经营主体购买守信激励计划服务须进行实名核验、主体信用审核，须按要求提交完整、真实的资料且履行相关程序。中雄征信将在三个工作日内完成核验和审核，并在核验和审核完成后十个工作日进行牌匾的制作和邮寄及信用展示。如因不可抗力或其它客观原因无法完成的，公司将全额退还服务费用。如出现已对企业进行复审并且荣誉牌匾完成制作并邮寄情况，费用将不做退还；</span>
      <span style='text-indent: 1.5em; text-align:justify;display: inline-block;line-height: 6vw;'>2、在办理守信激励计划服务业务过程中，针对经营主体提交的资料及填写的相关个人信息，公司将采取严格的保密措施，未经主体同意，不向任何第三方提供。中雄征信仅用于办理业务及与主体的业务联系、日常服务、快递、售后服务等，不做它用；</span>
      <span style='text-indent: 1.5em; text-align:justify;display: inline-block;line-height: 6vw;'>3、为了实现守信激励计划服务内容的定点推送，您同意并授权我们就注册时的信息（如电子邮件、电话号码等）在授权范围内进行合理适用。为了更好给您提供信用服务我们将根据您的需要：1、向您推送守信激励计划服务进度通知；2、就服务的完善向您提供咨询服务； 3、向您提供企业发展可能会感兴趣的产品或服务；4、为提升我们的服务，联系您进行调研；如您不需要上述推送的服务，您可在获取内容后选择退订或电话联系我们停止服务推送。</span>
      <span style='text-indent: 1.5em; text-align:justify;display: inline-block;line-height: 6vw;'>4、中雄征信建立完整的隐私保护机制，经营主体可通过公司提供人工电话服务：400-064-2818，咨询守信激励计划服务、了解服务进度、反馈服务问题、也可对我们进行投诉、建议、申请办理服务退款等；</span>
      <span style='text-indent: 1.5em; text-align:justify;display: inline-block;line-height: 6vw;'>5、如果在守信激励计划服务过程中，付费经营主体与公司产生纠纷的，双方首先进行友好协商解决，双方协商无果的，可向保定市莲池区人民法院提起诉讼。在这一过程中，您我双方同意，任何一方不得以任何方式侵害对方的名誉、商誉等，否则，任何一方均可追究对方的法律责任；</span>
      <span style='text-indent: 1.5em; text-align:justify;display: inline-block;line-height: 6vw;'>6、主体支付守信激励计划服务费后，表示主体已全面、仔细阅读了本说明。并认可将本说明作为约束双方的法律文件，双方均有义务依据本说明履行相关责任和义务。</span>
">
      <p slot="title" class="slotTitle">守信激励计划服务说明</p>
    </van-dialog>
    <!--    隐私说明-->
    <van-dialog
      v-model="YSshuoMing"
      confirm-button-color="#0075ff"
      confirm-button-text="已阅读"
      message-align="left"
      message="<span style='text-indent: 2em; text-align:justify;display: inline-block;line-height: 6vw;'>尊敬的用户：在您使用中雄征信“守信激励计划”时，请您务必审慎阅读并充分、透彻理解本政策并遵守《隐私政策协议》，特别是隐私保护、用户信息、免责申明等。</span>
      <p style='margin-top : 5vw;'>一、隐私保护</p>
      <span style='text-indent: 1.5em; text-align:justify;display: inline-block;line-height: 6vw;'>在您使用中雄征信“守信激励计划”过程中，您可能需要填写或者提交一些保证服务完成的必要信息，特别是您在初始注册系统时，我们将采集您企业公示的电话、邮箱和注册地址等相关信息，用以对您企业资质进行认证、确保企业身份真实并合法。用以联系和通知您，推送服务进度信息、确保将包含服务邮寄到您提供的地址处，更好的完成服务和后期提供更好的咨询服务。</span>
      <p style='margin-top : 5vw;'>二、用户信息收集</p>
      <span style='text-indent: 1.5em; text-align:justify;display: inline-block;line-height: 6vw;'>1.中雄征信提供服务时，我们会采集您企业的公开信息，包含但不限于您提供的企业公示电话、企业注册地址等信息；</span>
      <span style='text-indent: 1.5em; text-align:justify;display: inline-block;line-height: 6vw;'>2.采集的公开信息：</span>
      <span style='text-indent: 1.5em; text-align:justify;display: inline-block;line-height: 6vw;'>1）企业公示的电话、邮箱、企业注册地址、企业名称等相关信息；</span>
      <span style='text-indent: 1.5em; text-align:justify;display: inline-block;line-height: 6vw;'>2）企业公示的失信被执行、被执行人、行政处罚、重大负面舆情等公开信息；</span>
      <span style='text-indent: 1.5em; text-align:justify;display: inline-block;line-height: 6vw;'>3）企业公开的其他主体关联信息等。</span>
      <span style='text-indent: 1.5em; text-align:justify;display: inline-block;line-height: 6vw;'>4）除以上信息外，如果您有其他企业服务需求，我们为了能更好的提供服务和优化服务的合理需要，您同意并在我们联系您后您提供的相关信息。</span>
      <span style='text-indent: 1.5em; text-align:justify;display: inline-block;line-height: 6vw;'>我们承诺以上采集的企业公示信息仅用于我公司数据库的更新和比对，初衷是为了给您提供更好的服务体验。我们承诺对于发现的个人信息会进行严格的保密处理。</span>
      <p style='margin-top : 5vw;'>三、收集信息的用途：</p>
      <span style='text-indent: 1.5em; text-align:justify;display: inline-block;line-height: 6vw;'>1.守信激励计划的完成，如守信激励惠企服务包咨询服务的完成、信用信息公示、服务进度通知、服务邮寄等；</span>
      <span style='text-indent: 1.5em; text-align:justify;display: inline-block;line-height: 6vw;'>2.您企业信用信息的维护，我们在提供服务过程中会通过采集的信息与您联系，例如有关服务过程中需要通知您关于产品服务进度、注意事项等，主要是为了提供更好的服务体验； </span>
      <span style='text-indent: 1.5em; text-align:justify;display: inline-block;line-height: 6vw;'>3.向您推荐我们认为您企业可能感兴趣的企业服务，帮助您企业更好的成长。</span>
      <p style='margin-top : 5vw;'>四、免责声明</p>
      <span style='text-indent: 1.5em; text-align:justify;display: inline-block;line-height: 6vw;'>互联网是一个开放的平台，代表您企业公示的资料在中雄征信官网展示，有可能会被其他第三方组织和个人复制、擅改、转载或者其它非法用途，您必须充分意识此类风险的存在。您知悉并认可在使用“守信激励计划”所存在的风险，由于我们无法预知其他第三方为业务磋商给您带来的不便和困扰，为此，您同情并理解我们，不予追究我们的法律责任。</span>
      <span style='text-indent: 1.5em; text-align:justify;display: inline-block;line-height: 6vw;'>我们建立完整的个人隐私保护机制，如果您对本政策或者企业公示信息相关事宜有任何问题，请及时与我们联系，中雄征信官方客服电话：400-064-2818，将不愿意公开的信息进行隐藏、我们将根据您的要求，在第一时间进行处理。</span>
">
      <p slot="title" class="slotTitle">隐私政策</p>
    </van-dialog>
    <kefu class="kefu_class"></kefu>
  </div>
</template>

<script>
import bottom from '@/components/bottom/bottom'
import kefu from '@/components/kefu/kefu'
import { areaList } from '@vant/area-data'
import { addressSave, companyInfo, getopenid, weixinei, weixinWai, zhifubao } from '@/http/api/user'
import qs from 'qs'
import { Dialog, Toast } from 'vant'
import wx from 'weixin-jsapi'
import axios from 'axios'
export default {
  name: 'zhifu',
  // 注册组件
  components: { bottom, kefu },
  // 注册方法
  methods: {
    // 倒计时1
    startTimeSetInterVal () {
      this.timerSetInterVal = setInterval(this.valChange, 2000)
    },
    // 倒计时方法1
    valChange () {
      if (this.FuWuBaoZong) {
        clearInterval(this.timerSetInterVal)
      } else {
        if (this.shouNum === 1) {
          this.paibianImg = require('../../assets/ZhiFuImg/huiqi2_618.png')
          this.fuwubao1 = false
          this.fuwubao2 = true
          this.fuwubao3 = false
          this.fuwubao4 = false
          this.$refs.applyForUrl.style.top = '30vw'
          this.shouNum++
        } else if (this.shouNum === 2) {
          this.paibianImg = require('../../assets/ZhiFuImg/huiqi3_588.png')
          this.fuwubao1 = false
          this.fuwubao2 = false
          this.fuwubao3 = true
          this.fuwubao4 = false
          this.$refs.applyForUrl.style.top = '46vw'
          this.shouNum++
        } else if (this.shouNum === 3) {
          this.paibianImg = require('../../assets/ZhiFuImg/huiqi4_288.png')
          this.fuwubao1 = false
          this.fuwubao2 = false
          this.fuwubao3 = false
          this.fuwubao4 = true
          this.$refs.applyForUrl.style.top = '62vw'
          this.shouNum++
        } else if (this.shouNum === 4) {
          this.paibianImg = require('../../assets/ZhiFuImg/huiqi1_788.png')
          this.fuwubao1 = true
          this.fuwubao2 = false
          this.fuwubao3 = false
          this.fuwubao4 = false
          this.$refs.applyForUrl.style.top = '13vw'
          this.shouNum = 1
        }
      }
    },
    // 价格重新上色
    resizeJiaGe () {
      this.gao = true
      this.dijiaOne = false
      this.dijiaTwo = false
    },
    // 服务包一
    jiage1FuWuBao () {
      document.getElementById('gundongFuWu').scrollIntoView({ behavior: 'smooth' })
      this.priceNum = '788'
      this.resizeJiaGe()
      this.paibianImg = require('../../assets/ZhiFuImg/huiqi1_788.png')
      this.FuWuBaoZong = true
      this.fuwubaoTwo1 = true
      this.fuwubaoTwo2 = false
      this.fuwubaoTwo3 = false
      this.fuwubaoTwo4 = false
      this.fuwubaoFirst1 = true
      this.fuwubaoFirst2 = false
      this.fuwubaoFirst3 = false
      this.fuwubaoFirst4 = false
      this.fuwubao1 = true
      this.fuwubao2 = false
      this.fuwubao3 = false
      this.fuwubao4 = false
    },
    // 服务包二
    jiage2FuWuBao () {
      document.getElementById('gundongFuWu').scrollIntoView({ behavior: 'smooth' })
      this.priceNum = '618'
      this.resizeJiaGe()
      this.paibianImg = require('../../assets/ZhiFuImg/huiqi2_618.png')
      this.FuWuBaoZong = true
      this.fuwubaoTwo1 = false
      this.fuwubaoTwo2 = true
      this.fuwubaoTwo3 = false
      this.fuwubaoTwo4 = false
      this.fuwubaoFirst1 = false
      this.fuwubaoFirst2 = true
      this.fuwubaoFirst3 = false
      this.fuwubaoFirst4 = false
      this.fuwubao1 = false
      this.fuwubao2 = true
      this.fuwubao3 = false
      this.fuwubao4 = false
    },
    // 服务包三
    jiage3FuWuBao () {
      document.getElementById('gundongFuWu').scrollIntoView({ behavior: 'smooth' })
      this.priceNum = '588'
      this.resizeJiaGe()
      this.paibianImg = require('../../assets/ZhiFuImg/huiqi3_588.png')
      this.FuWuBaoZong = true
      this.fuwubaoTwo1 = false
      this.fuwubaoTwo2 = false
      this.fuwubaoTwo3 = true
      this.fuwubaoTwo4 = false
      this.fuwubaoFirst1 = false
      this.fuwubaoFirst2 = false
      this.fuwubaoFirst3 = true
      this.fuwubaoFirst4 = false
      this.fuwubao1 = false
      this.fuwubao2 = false
      this.fuwubao3 = true
      this.fuwubao4 = false
    },
    // 服务包四
    jiage4FuWuBao () {
      document.getElementById('gundongFuWu').scrollIntoView({ behavior: 'smooth' })
      this.priceNum = '288'
      this.resizeJiaGe()
      this.paibianImg = require('../../assets/ZhiFuImg/huiqi4_288.png')
      this.FuWuBaoZong = true
      this.fuwubaoTwo1 = false
      this.fuwubaoTwo2 = false
      this.fuwubaoTwo3 = false
      this.fuwubaoTwo4 = true
      this.fuwubaoFirst1 = false
      this.fuwubaoFirst2 = false
      this.fuwubaoFirst3 = false
      this.fuwubaoFirst4 = true
      this.fuwubao1 = false
      this.fuwubao2 = false
      this.fuwubao3 = false
      this.fuwubao4 = true
    },
    // 价格一
    jiage1 (price) {
      if (this.fuwubao1) {
        this.paibianImg = require('../../assets/ZhiFuImg/huiqi1_788.png')
      } else if (this.fuwubao2) {
        this.paibianImg = require('../../assets/ZhiFuImg/huiqi2_618.png')
      } else if (this.fuwubao3) {
        this.paibianImg = require('../../assets/ZhiFuImg/huiqi3_588.png')
      } else if (this.fuwubao4) {
        this.paibianImg = require('../../assets/ZhiFuImg/huiqi4_288.png')
      }
      this.priceNum = price
      this.gao = true
      this.dijiaOne = false
      this.dijiaTwo = false
    },
    // 价格二
    jiage2 (price) {
      if (this.fuwubao1) {
        this.paibianImg = require('../../assets/ZhiFuImg/huiqi1_688.png')
      } else if (this.fuwubao2) {
        this.paibianImg = require('../../assets/ZhiFuImg/huiqi2_518.png')
      } else if (this.fuwubao3) {
        this.paibianImg = require('../../assets/ZhiFuImg/huiqi3_488.png')
      } else if (this.fuwubao4) {
        this.paibianImg = require('../../assets/ZhiFuImg/huiqi4_188.png')
      }
      this.priceNum = price
      this.gao = false
      this.dijiaOne = true
      this.dijiaTwo = false
    },
    // 价格三
    jiage3 (price) {
      if (this.fuwubao1) {
        this.paibianImg = require('../../assets/ZhiFuImg/huiqi1_588.png')
      } else if (this.fuwubao2) {
        this.paibianImg = require('../../assets/ZhiFuImg/huiqi2_460.png')
      } else if (this.fuwubao3) {
        this.paibianImg = require('../../assets/ZhiFuImg/huiqi3_430.png')
      } else if (this.fuwubao4) {
        this.paibianImg = require('../../assets/ZhiFuImg/huiqi4_130.png')
      }
      this.priceNum = price
      this.gao = false
      this.dijiaOne = false
      this.dijiaTwo = true
    },
    // 拨打客服电话
    boZixun () {
      window.location.href = 'tel://4000642818'
    },
    // 微信客服
    weiZixun () {
      window.location.href = 'https://work.weixin.qq.com/kfid/kfcc327addc3b588368'
    },
    // 关闭弹窗
    AddressshowClose () {
      this.Addressshow = false
      this.AddressORZhiFu = false
    },
    // 支付
    zhifu () {
      if (this.priceNum === '') {
        Toast('请选择相应的服务及价格')
        return
      }
      this.Addressshow = true
      this.AddressORZhiFu = true
    },
    // 公司名称One适配
    companyzuheFun () {
      const dom = this.$refs.companyzuhe
      const lay = dom.offsetWidth
      const span = dom.children[0].offsetWidth
      const average = span / dom.children[0].innerHTML.split('').length
      if (span + average * 1 > lay) {
        const s = (lay - average * 1) / span
        dom.style.transform = `scale(${s})`
      }
    },
    // 公司名称Two适配
    companyzuheFunTwo () {
      const dom = this.$refs.companyzuheTwo
      const lay = dom.offsetWidth
      const span = dom.children[0].offsetWidth
      const average = span / dom.children[0].innerHTML.split('').length
      if (span + average * 1 > lay) {
        const s = (lay - average * 1) / span
        dom.style.transform = `scale(${s})`
      }
    },
    // 公司名称Three适配
    companyzuheFunThree () {
      const dom = this.$refs.companyzuheThree
      const lay = dom.offsetWidth
      const span = dom.children[0].offsetWidth
      const average = span / dom.children[0].innerHTML.split('').length
      if (span + average * 1 > lay) {
        const s = (lay - average * 1) / span
        dom.style.transform = `scale(${s})`
      }
    },
    // 修改地址
    async submitAddress (content) {
      this.AddressInfo = content
      this.Addressshow = false
      this.submitaddressBtn = true
      console.log(content)
      const data = await addressSave(qs.stringify({
        province: content.province,
        city: content.city,
        area: content.county,
        address: content.addressDetail,
        code: content.areaCode,
        name: content.name,
        phone: content.tel,
        random: this.random
      }))
      if (Number(data.code) === 200) {
        this.submitaddressBtn = false
        if (this.AddressORZhiFu) {
          if (this.zhifuId === 1) {
            Toast('您已支付，无需重复支付')
          } else {
            const prices = this.priceNum
            let serverNum = 0
            if (this.fuwubao1) {
              serverNum = 1
            } else if (this.fuwubao2) {
              serverNum = 2
            } else if (this.fuwubao3) {
              serverNum = 3
            } else if (this.fuwubao4) {
              serverNum = 4
            }
            if (this.radio === '1') {
              // 检测是否是微信
              const ua = navigator.userAgent.toLowerCase()
              if (/micromessenger/.test(ua)) {
                const data = await weixinei(qs.stringify({
                  openid: localStorage.getItem('openid'),
                  random: localStorage.getItem('RandomNum'),
                  price: prices,
                  service: serverNum
                }))
                // 存储订单编号
                localStorage.setItem('orderNo', data.data.order_no)
                wx.config({
                  debug: false, // 开启调试模式
                  appId: data.data.jsApiParameters.appId, // 公众号的唯一标识
                  timestamp: data.data.jsApiParameters.timeStamp, // 生成签名的时间戳
                  nonceStr: data.data.jsApiParameters.nonceStr, // 生成签名的随机串
                  signature: data.data.jsApiParameters.signType, // 签名
                  jsApiList: ['chooseWXPay'] // 填入需要使用的JS接口列表，这里是先声明我们要用到支付的JS接口
                })
                wx.chooseWXPay({
                  appId: data.data.jsApiParameters.appId, // appId
                  timestamp: data.data.jsApiParameters.timeStamp, // 支付签名时间戳
                  nonceStr: data.data.jsApiParameters.nonceStr, // 支付签名随机串
                  package: data.data.jsApiParameters.package, // 统一支付接口返回的prepay_id参数值
                  signType: data.data.jsApiParameters.signType, // 签名方式
                  paySign: data.data.jsApiParameters.paySign, // 支付签名(签名算法看微信开放文档的js-sdk文档的附录1，当然这是后端的事情)
                  // 支付成功，跳入支付成功页面，点击完成按钮会进入success和complete函数
                  success: function (res) {
                    if (res.errMsg === 'chooseWXPay:ok') { // 支付成功进入这个判断
                      Toast.success('支付成功!')
                      window.location.href = 'https://p8b.cn/success'
                    }
                  },
                  // 用户取消支付--实际上进入cancel 和 complate 函数
                  cancel: function (res) {
                    Toast.fail('取消支付')
                  },
                  // 支付失败
                  fail: function (res) {
                    Toast.fail('支付失败')
                  }
                })
              } else {
                const { data } = await weixinWai(qs.stringify({
                  random: localStorage.getItem('RandomNum'),
                  price: prices,
                  service: serverNum
                }))
                // 存储订单编号
                localStorage.setItem('orderNo', data.order_no)
                window.location.href = data.jsApiParameters.h5_url
              }
            } else {
              const data = await zhifubao(qs.stringify({
                random: localStorage.getItem('RandomNum'),
                price: prices,
                service: serverNum
              }))
              // 存储订单编号
              localStorage.setItem('orderNo', data.data.order_no)
              window.location.href = data.data.h5_url
            }
          }
        }
        this.AddressORZhiFu = false
      }
    },
    // 修改地址弹窗开启
    addressUpdate () {
      this.Addressshow = true
    },
    // 记录不用管
    async companyInfo () {
      const { data } = await companyInfo(qs.stringify({
        random: this.random,
        type: '2'
      }))
      this.companyImgInfo = data.paibian
      this.AddressInfo = data.AddressInfo
      this.companyName = data.comnpany_info.company
      this.zhifuId = data.pay_flag
      console.log(data.AddressInfo)
      axios({
        method: 'get',
        // 请求路径
        url: 'https://restapi.amap.com/v3/geocode/geo?parameters',
        // 请求参数
        params: {
          key: '1a82fe22ffee11316b8984286a4f51a0',
          address: this.AddressInfo.addressDetail
        }
      }).then(({ data }) => {
        // 成功回调
        this.AddressInfo.areaCode = data.geocodes[0].adcode
      })
    },
    // 回调url中截取code字符串
    getQueryString (name) {
      let reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)', 'i')
      let r = window.location.search.substr(1).match(reg) // 获取url中"?"符后的字符串并正则匹配
      let context = ''
      if (r != null) context = r[2]
      reg = null
      r = null
      // eslint-disable-next-line eqeqeq
      return context == null || context == '' || context == 'undefined' ? '' : context
    },
    // 获取openid
    async getOpenid (code) {
      const data = await getopenid(qs.stringify({
        code: code
      }))
      localStorage.setItem('openid', data.data)
    }
  },
  // 父子传值
  props: {},
  // 数据渲染
  data () {
    return {
      // 定时器1
      timerSetInterVal: null,
      // 小手计算
      shouNum: 1,
      // 默认价格
      priceNum: '',
      // 服务包总控制阀
      FuWuBaoZong: false,
      // 惠企服务包下选项
      fuwubaoTwo1: false,
      fuwubaoTwo2: false,
      fuwubaoTwo3: false,
      fuwubaoTwo4: false,
      // 服务包一、二、三、四（起始值）
      fuwubaoFirst1: true,
      fuwubaoFirst2: true,
      fuwubaoFirst3: true,
      fuwubaoFirst4: true,
      // 服务包一、二、三、四
      fuwubao1: true,
      fuwubao2: false,
      fuwubao3: false,
      fuwubao4: false,
      // 选择图片
      paibianImg: require('../../assets/ZhiFuImg/huiqi1_788.png'),
      // 判断是正常修改地址还是支付
      AddressORZhiFu: false,
      // 牌匾信息
      companyImgInfo: {},
      // 隐私说明
      YSshuoMing: false,
      // 守信服务说明
      SXfuwushuoMing: false,
      // 判断是否支付
      zhifuId: '',
      // openid
      openidNum: '',
      // 随机值
      random: '',
      // 公司名称
      companyName: '',
      // 提交收货地址加载动画
      submitaddressBtn: false,
      // 收货信息
      AddressInfo: {
        name: '',
        tel: '',
        province: '',
        areaCode: '',
        city: '',
        county: '',
        addressDetail: ''
      },
      // 地址弹出层
      Addressshow: false,
      // 地区列表
      areaList,
      // 支付选择
      radio: '1',
      // 服务文案
      fuwuArrBox: [
        {
          nums: require('../../assets/ZhiFuImg/wuliu.png'),
          biaotititle: '支持费用后，相关如何获取?',
          neirongtext1: '1、荣誉牌匾邮寄，我们统一选择“中国邮政(EMS)”邮寄，解决遇到快递盲区，荣誉牌匾邮寄不到问题；',
          neirongtext2: '2、惠企服务包服务流程，完成付款→付款成功短信回执→获得惠企服务包服务→荣誉牌匾制作与邮寄完成短信提醒→最终客户收到实物荣誉牌匾；',
          neirongtext3: '3、荣誉牌匾进度查询，提供微信公众号（守信企业计划）、守信企业计划官网、客服电话人工咨询三种方式查询，客服人员做到及时反馈，确保服务交付质量。',
          neirongtext4: '',
          neirongtext5: ''
        },
        {
          nums: require('../../assets/ZhiFuImg/fuwu.png'),
          biaotititle: '服务保障',
          neirongtext1: '1、服务体验保障，为了确保客户能快速与我们取得联系，我们400电话早8点到晚20点会保证畅通，提升体验；',
          neirongtext2: '2、服务交付保障，凡加入守信激励计划的企业，我们承诺惠企服务包的相关服务我们保质保量的完成交付，其中荣誉牌匾做到地址准确必须完成邮寄，如牌匾出现变形，裂开等问题，我们保证包退包换。',
          neirongtext3: '',
          neirongtext4: '',
          neirongtext5: ''
        },
        {
          nums: require('../../assets/ZhiFuImg/jinqian.png'),
          biaotititle: '为什么需要支付费用?',
          neirongtext1: '相关费用说明，您支付的费用主要用于以下：',
          neirongtext2: '1、人工审核成本，需要对企业资质、企业信息审核；',
          neirongtext3: '2、咨询服务成本，惠企服务包里的咨询服务需要专业团队完成，会有最基础的人员工资成本；',
          neirongtext4: '3、实体荣誉牌匾的制作与邮寄成本；',
          neirongtext5: '4、企业信用信息的展示和信息维护的成本。'
        }
      ],
      // 高价788
      gao: true,
      // 低价618
      dijiaOne: false,
      // 低价588
      dijiaTwo: false,
      // 低价288
      dijiaThree: false
    }
  },
  // 计算属性
  computed: {},
  // 监听器
  watch: {
    companyName () {
      this.$nextTick(() => {
        this.companyzuheFun()
        this.companyzuheFunTwo()
        this.companyzuheFunThree()
      })
    }
  },
  // 页面初次解析完成
  created () {
    // 判断地址中是否有code 如果有调用截取code及后续操作
    // eslint-disable-next-line eqeqeq
    if (window.location.search.indexOf('code') != -1) {
      const code = this.getQueryString('code')
      this.getOpenid(code)
    }
    // 是否有随机值
    if (localStorage.getItem('RandomNum') !== null && localStorage.getItem('RandomNum') !== '') {
      this.random = localStorage.getItem('RandomNum')
    } else {
      Dialog({ message: '非法访问！请从首页进入！' }).then(() => {
        window.opener = null
        this.$router.go(0)
      })
    }
    // 记录初始值
    this.companyInfo()
    // 顶部
    window.scrollTo({
      left: 0,
      top: 0
    })
    setTimeout(function () {
      document.getElementById('zhifu').style.visibility = 'visible'
    }, 10)
  },
  // 页面内容渲染完成
  mounted () {
    // 公司名称适配One
    this.companyzuheFun()
    this.companyzuheFunTwo()
    this.companyzuheFunThree()
    // 滚动
    document.getElementById('gundong').scrollIntoView({ behavior: 'smooth' })
    // 小手倒计时1
    this.startTimeSetInterVal()
  }
}
</script>

<style scoped lang="less">
// 客服
.kefu_class{
  position: fixed;
  right: 5px;
  bottom: 45px;
}
// LOGO
.logoBox{
  padding: 15px 15px 0px 15px;
  // 符合加入
  .ChengLI{
    padding: 20px 0px;
    background-image: url("../../assets/ZhiFuImg/zhifubgc.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: center;
    .gengduoyiBox_p1{
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 15px;
      font-weight: bold;
      letter-spacing: 2px;
      color: #cf2020;
      .henggang{
        width: 40px;
        height: 3px;
        background-color: #cf2020;
      }
      .henggang2{
        margin-left: 9px;
      }
      .henggang1{
        margin-right: 11px;
      }
    }
    .gengduoyiBox_p2{
      text-align: justify;
      margin: 12px auto 0px;
      width: 310px;
      line-height: 18px;
      font-size: 12px;
      color: #333333;
    }
  }
}
/deep/.van-dialog__message{
  white-space: normal;
}
// 守信承诺书标题
.slotTitle{
  font-size: 18px;
  color: #0075ff;
}
// 修改地址按钮内边距
/deep/ .van-address-edit__buttons{
  padding: 10px 0px 0px 0px !important;
}
// 修改地址按钮颜色
/deep/ .van-button--danger{
  background-color: #d65c1c !important;
  font-size: 16px !important;
  font-weight: bolder !important;
}
// 修改地址
.addressBox{
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%,-50%);
  width: 90%;
  background-color: #ffffff;
  border-radius: 8px;
  .addressBox_p1{
    margin-top: 25px;
    text-align: center;
    font-size: 20px;
    color: #d65c1c;
    font-weight: bolder;
    letter-spacing: 4px;
    margin-left: 4px;
  }
}
// 价格类
.publicColor1{
  color: #d65c1c !important;
}
// 价格类
.publicColor2{
  color: #333333 !important;
}
// 价格边框类
.publicBorder{
  border: solid 1px #d65c1c !important;
}

// 公共内边距
.paddingPublic{
  padding: 18px;
}
// 公共圆角度
.radiuPublic{
  border-radius: 8px;
}
#zhifu{
  // 主体
  .zhifuBox{
    .zhifuBox_Img{
      position: absolute;
      z-index: -1;
      width: 100%;
      height: 180px;
    }
    // 牌匾支付
    .paibianZhiFu{
      margin-top: 9px;
      .paibianZhiFuBox{
        padding: 10px;
        background-color: #ffffff;
        box-shadow: 0px 3px 4px 0px
        rgba(7, 0, 2, 0.32);
        border-radius: 8px;
        border: solid 1px #c0040d;
      }
      .paibianZhiFu_p1{
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        font-size: 18px;
        color: #c0040d;
        font-weight: 900;
        .liwu1,
        .liwu2{
          width: 22px;
          height: 22px;
        }
        .liwu1{
          margin-right: 6px;
        }
        .liwu2{
          margin-left: 4px;
        }
      }
      // 牌匾图区域
      .paibianImg{
        position: relative;
        height: 200px;
        margin: 15px auto 0px;
        .paibianImg1{
          display: block;
          width: 100%;
          height: 100%;
        }
        // 组合牌匾
        .companyzuhe{
          position: absolute;
          left: 49px;
          top: 87px;
          margin: 0 auto;
          width: 120px;
          .autoCompanyZuhe{
            width:100%;
            display: flex;
            justify-content: center;
            .autoCompanyZuhe_span{
              white-space:nowrap;
              font-size: 12px;
              transform: scale(.9);
              color: #333333;
              font-weight: bold;
            }
          }
        }
        // 木制牌匾
        .companyMu{
          position: absolute;
          left: 42px;
          top: 83px;
          margin: 0 auto;
          width: 100px;
          .autoCompanyZuhe{
            width:100%;
            display: flex;
            justify-content: center;
            .autoCompanyZuhe_span{
              white-space:nowrap;
              font-size: 12px;
              transform: scale(.8);
              color: #333333;
              font-weight: bold;
            }
          }
        }
        // 铜牌牌匾
        .companyTong{
          position: absolute;
          left: 32px;
          top: 76px;
          margin: 0 auto;
          width: 121px;
          .autoCompanyZuhe{
            width:100%;
            display: flex;
            justify-content: center;
            .autoCompanyZuhe_span{
              white-space:nowrap;
              font-size: 12px;
              transform: scale(.8);
              color: #333333;
              font-weight: bold;
            }
          }
        }
        // 服务包组合
        .companyBoxFuWu{
          position: absolute;
          left: 22px;
          top: 86px;
          margin: 0 auto;
          width: 150px;
          .autoCompanyZuhe{
            width:100%;
            display: flex;
            justify-content: center;
            .autoCompanyZuhe_span{
              white-space:nowrap;
              font-size: 12px;
              transform: scale(.9);
              color: #333333;
              font-weight: bold;
            }
          }
        }
        // 服务包铜牌
        .companyTongFuWu{
          position: absolute;
          left: 50%;
          transform: translateX(-50%);
          top: 80px;
          margin: 0 auto;
          width: 165px;
          .autoCompanyZuhe{
            width:100%;
            display: flex;
            justify-content: center;
            .autoCompanyZuhe_span{
              white-space:nowrap;
              font-size: 12px;
              color: #333333;
              font-weight: bold;
            }
          }
        }
        // 服务包木牌
        .companyMuFuWu{
          position: absolute;
          left: 50%;
          transform: translateX(-50%);
          top: 84px;
          margin: 0 auto;
          width: 125px;
          .autoCompanyZuhe{
            width:100%;
            display: flex;
            justify-content: center;
            .autoCompanyZuhe_span{
              white-space:nowrap;
              font-size: 12px;
              transform: scale(.8);
              color: #333333;
              font-weight: bold;
            }
          }
        }
        // 服务包木牌组合
        .companyMuZuHeFuWu{
          position: absolute;
          right: 29px;
          top: 100px;
          margin: 0 auto;
          width: 90px;
          .autoCompanyZuhe{
            width:100%;
            display: flex;
            justify-content: center;
            .autoCompanyZuhe_span{
              white-space:nowrap;
              font-size: 12px;
              transform: scale(.8);
              color: #333333;
              font-weight: bold;
            }
          }
        }
        // 服务包隐藏
        .companyFuWu{
          opacity: 0 !important;
        }
      }
      // 选择价格
      .paibianJiage{
        padding-top: 10px;
        position: relative;
        padding-bottom: 8px;
        margin-top: 20px;
        background-color: #ffffff;
        box-shadow: 0px 3px 4px 0px
        rgba(7, 0, 2, 0.32);
        border: solid 2px #ffb83d;
        .shou1{
          position: absolute;
          width: 30px;
          top: 13vw;
          right: 5px;
          z-index: 1;
          transition: all 0.3s;
        }
        .shou2{
          position: absolute;
          width: 23px;
          top: 29vw;
          right: 2px;
          z-index: 1;
          transition: all 0.3s;
        }
        @keyframes xing {
          0% {
            transform: scale(1);
          }
          25% {
            transform: scale(1.1);
          }
          50% {
            transform: scale(1);
          }
          75% {
            transform: scale(1.1);
          }
        }
        .my_xing {
          -webkit-animation-name: xing;
          -webkit-animation-timing-function: ease-in-out;
          -webkit-animation-iteration-count: infinite;
          -webkit-animation-duration: 2s;
        }
        .paibianJiage_p1{
          display: flex;
          align-items: center;
          justify-content: center;
          position: absolute;
          left: 50%;
          transform: translateX(-50%);
          top: -11px;
          width: 275px;
          height: 20px;
          text-align: center;
          background-color: #ffffff;
          font-size: 15px;
          letter-spacing: 1px;
          color: #ffb83d;
          font-weight: 900;
        }
        .jiageActive{
          border: solid 2px #ff0000 !important;
        }
        .jiage1_leftAvtive{
          border-right: 2px solid #ff0000 !important;
        }
        .jiage1_left_top1Active{
          color: #ff0000 !important;
        }
        .jiage1_left_topActive{
          color: #ff0000 !important;
        }
        .jiage1{
          display: flex;
          margin: 12px auto 0px;
          width: 300px;
          height: 40px;
          background-color: #ffffff;
          border-radius: 4px;
          border: solid 2px #666666;
          .jiage1_right{
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            width: 230px;
            height: 100%;
            .jiage1_left_top1{
              font-size: 14px;
              color: #666666;
            }
            .jiage1_left_bottom1{
              text-align: center;
              font-size: 12px;
              color: #999999;
              text-decoration: line-through;
              width: 255px;
              transform: scale(.8);
            }
          }
          .jiage1_left{
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            width: 70px;
            height: 100%;
            border-right: 2px solid #666666;
            .jiage1_left_top{
              font-size: 16px;
              color: #666666;
            }
            .jiage1_left_bottom{
              font-size: 12px;
              color: #999999;
              text-decoration: line-through;
              transform: scale(.8);
            }
          }
        }
      }
      // 服务包字体
      .jiage1FuWuBaoFont{
        margin: 10px auto 0;
        padding: 5px 0px;
        text-indent: 3px;
        font-size: 14px;
        font-weight: bolder;
        letter-spacing: 3px;
        text-align: center;
        color: #101013;
        width: 300px;
        border-radius: 6px;
        background-color: #ffcf23;
        transform: scale(0.97);
      }
      @keyframes jiage1FuWuBaoFontKey {
        0% {
          transform: scale(0.97);
        }
        25% {
          transform: scale(1.04);
        }
        50% {
          transform: scale(0.97);
        }
        75% {
          transform: scale(1.04);
        }
      }
      .jiage1FuWuBaoFontDong {
        -webkit-animation-name: jiage1FuWuBaoFontKey;
        -webkit-animation-timing-function: ease-in-out;
        -webkit-animation-iteration-count: infinite;
        -webkit-animation-duration: 2s;
      }
      // 服务包样式
      .jiage1_leftFuWuBaoAvtive{
        border-right: 2px solid #ab890e !important;
      }
      .jiage1_left_top1FuWuBaoActive{
        color: #ab890e !important;
      }
      .jiage1_left_topFuWuBaoActive{
        color: #ab890e !important;
      }
      .jiageFuWuBaoActive{
        border: solid 2px #ab890e !important;
      }
      .jiage1FuWuBao{
        display: flex;
        margin: 10px auto 0px;
        width: 300px;
        height: 50px;
        background-color: #ffffff;
        border-radius: 4px;
        font-weight: bolder;
        border: solid 2px #666666;
        .jiage1_rightFuWuBao{
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          width: 230px;
          height: 100%;
          .jiage1_left_top1FuWuBao{
            font-size: 15px;
            color: #666666;
          }
          .jiage1_left_bottom1FuWuBao{
            text-align: center;
            font-size: 12px;
            color: #999999;
            width: 250px;
            transform: scale(.8);
          }
        }
        .jiage1_leftFuWuBao{
          display: flex;
          align-items: center;
          width: 55px;
          text-align: center;
          height: 100%;
          border-right: 2px solid #666666;
          .jiage1_left_topFuWuBao{
            font-size: 14px;
            color: #666666;
          }
        }
      }
      .jiage2FuWuBao{
        display: flex;
        margin: 6px auto 0px;
        width: 300px;
        height: 50px;
        background-color: #ffffff;
        border-radius: 4px;
        font-weight: bolder;
        border: solid 2px #666666;
        .jiage1_rightFuWuBao{
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          width: 230px;
          height: 100%;
          .jiage1_left_top1FuWuBao{
            font-size: 15px;
            color: #666666;
          }
          .jiage1_left_bottom1FuWuBao{
            text-align: center;
            font-size: 12px;
            color: #999999;
            width: 250px;
            transform: scale(.8);
          }
        }
        .jiage1_leftFuWuBao{
          display: flex;
          align-items: center;
          width: 55px;
          text-align: center;
          height: 100%;
          border-right: 2px solid #666666;
          .jiage1_left_topFuWuBao{
            font-size: 14px;
            color: #666666;
          }
        }
      }
      // 微信支付支付宝样式
      .WeChatAndZfb{
        width: 100%;
        margin-top: 12px;
        display: flex;
        justify-content: center;
        align-items: center;
        .paymentRadio{
          font-size: 14px;
          font-weight: bold;
          letter-spacing: 0px;
          color: #333333;
          .van-radio--horizontal{
            margin-right: 0px;
          }
          .wechatImg{
            display: flex;
            align-items: center;
            .wechatImg_img{
              width: 22px;
              height: 22px;
              margin-right: 8px;
            }
          }
        }
      }
      // 立即去支付
      .ZhiFuBtn{
        display: block;
        border: none;
        margin: 10px auto 0px;
        width: 250px;
        height: 40px;
        background-color: #c0040d;
        box-shadow: 0px 3px 5px 0px
        rgba(0, 0, 0, 0.35);
        border-radius: 20px;
        .ZhiFuBtn_span1{
          margin-top: 3px;
          display: block;
          font-size: 15px;
          font-weight: bolder;
          letter-spacing: 6px;
          margin-left: 6px;
          color: #ffffff;
        }
        .ZhiFuBtn_span2{
          display: block;
          font-size: 12px;
          color: #ffffff;
          transform: scale(.75);
        }
      }
      // 阅读协议
      .yuedu{
        margin-top: 10px;
        text-align: center;
        transform: scale(.85);
        font-size: 12px;
        color: #666666;
      }
    }
    // 售前资讯
    .ZiXunBox{
      margin-top: 15px;
      .ZiXunBoxContent{
        height: 70px;
        padding: 10px;
        background-image: url("../../assets/indexImg/zixun.png");
        background-repeat: no-repeat;
        background-size: 100% 100%;
        background-color: #ffffff;
        box-shadow: 0px 2px 5px 0px
        rgba(0, 0, 0, 0.2);
        border-radius: 8px;
        .ZiXunBoxContent_img{
          display: flex;
          width: 80px;
        }
        .ZiXunBoxContentBox{
          margin-top: 10px;
          display: flex;
          align-items: center;
          justify-content: space-evenly;
          .ZiXunBoxContentBox_left,
          .ZiXunBoxContentBox_right{
            width: 123px;
          }
        }
      }
    }
    // 修改地址
    .addressXiu{
      .addressXiuBoxBox{
        margin-top: 15px;
        padding: 10px;
        background-image: url("../../assets/indexImg/zixun.png");
        background-repeat: no-repeat;
        background-size: 100% 100%;
        background-color: #ffffff;
        box-shadow: 0px 2px 5px 0px
        rgba(0, 0, 0, 0.35);
        border-radius: 8px;
        .addressXiuBox{
          padding: 0px 10px 10px 10px;
          position: relative;
          margin-top: 18px;
          border: solid 2px #ffb83d;
          .topText{
            position: absolute;
            top: -18px;
            left: 50%;
            transform: translateX(-50%);
            width: 226px;
            height: 35px;
            line-height: 35px;
            text-align: center;
            background-color: #ffb83d;
            font-size: 17px;
            font-weight: bolder;
            color: #333333;
          }
          .textBox{
            margin-top: 28px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            .textBox_left{
              display: flex;
              align-items: center;
              .textBox_left_p1{
                margin-right: 5px;
                font-size: 15px;
                font-weight: bold;
                letter-spacing: 1px;
                color: #333333;
              }
              .textBox_left_p2{
                padding: 3px;
                border-radius: 3px;
                border: solid 1px #f5b26c;
                font-size: 12px;
                font-weight: bold;
                color: #f5b26c;
              }
            }
            .textBox_right{
              font-size: 12px;
              font-weight: bolder;
              letter-spacing: 1px;
              color: #377ff5;
            }
          }
          .text_p1{
            margin-top: 10px;
            font-size: 12px;
            font-weight: bold;
            line-height: 12px;
            letter-spacing: 1px;
            color: #333333;
          }
        }
      }
    }
    // 守信计划保障
    .baozhang{
      margin-top: 15px;
      .baozhangBox{
        padding: 10px;
        background-image: url("../../assets/indexImg/gengduo2.png");
        background-repeat: no-repeat;
        background-size: 100% 100%;
        background-color: #ffffff;
        box-shadow: 0px 2px 5px 0px
        rgba(0, 0, 0, 0.35);
        border-radius: 8px;
        .baozhangBox1{
          display: flex;
          align-items: center;
          .baozhangBox1_img{
            width: 18px;
            height: 18px;
          }
          .baozhangBox1_plan{
            margin-left: 8px;
            font-size: 15px;
            font-weight: bolder;
            letter-spacing: 2px;
            color: #1b8ae0;
          }
          .baozhangBox1_p{
            margin-left: 8px;
            font-size: 15px;
            font-weight: bolder;
            letter-spacing: 2px;
            color: #cf2020;
          }
        }
        .baozhangBox1_p2lan{
          margin-top: 10px;
          line-height: 22px;
          text-align: justify;
          font-size: 12px;
          color: #333333;
        }
        .baozhangBox1_p2{
          margin-top: 10px;
          font-size: 13px;
          font-weight: bold;
          letter-spacing: 1px;
          color: #333333;
        }
        .baozhangBox1_p1{
          width: 330px;
          margin-top: 5px;
          font-size: 12px;
          letter-spacing: 1px;
          color: #333333;
        }
        .baozhangBox1_p3{
          text-align: justify;
          margin-top: 10px;
          font-size: 12px;
          color: #cf2020;
        }
        .baozhangBox1_p3lan{
          text-align: justify;
          margin-top: 10px;
          font-size: 12px;
          color: #1b8ae0;
        }
      }
    }
    // 服务说明
    .fuwushuomingBox{
      margin-top: 15px;
      .fuwushuomingBoxBox{
        padding: 10px;
        background-image: url("../../assets/indexImg/zhifugengduo2.png");
        background-repeat: no-repeat;
        background-size: 100% 100%;
        background-color: #ffffff;
        box-shadow: 0px 2px 5px 0px
        rgba(0, 0, 0, 0.35);
        border-radius: 8px;
      }
      .fuwuText{
        margin: 0 auto 0;
        width: 201px;
        height: 35px;
        background-color: #ffb83d;
        font-size: 17px;
        text-align: center;
        line-height: 35px;
        letter-spacing: 2px;
        font-weight: bolder;
        color: #333333;
      }
      .fuwuBoxText{
        margin: 10px 0px;
        display: flex;
        height: 18px;
        .fuwuBoxTextTop{
          display: flex;
          align-items: center;
          .fuwuBoxTextTop_left{
            width: 18px;
            height: 18px;
            font-size: 12px;
            text-align: center;
            color: white;
            border-radius: 50%;
            line-height: 18px;
            .fuwuBoxTextTop_left_img{
              width: 17px;
            }
          }
          .fuwuBoxTextTop_right{
            margin-left: 10px;
            font-size: 15px;
            font-weight: bolder;
            color: #333333;
          }
        }
      }
        .fuwuBoxText_p1{
          margin-bottom: 5px;
          line-height: 20px;
          font-size: 12px;
          color: #333333;
        }
    }
  }
}
</style>
