import Vue from 'vue'
import VueRouter from 'vue-router'
import index from '@/views/index'
import zhifu from '@/views/zhifu/zhifu'
import success from '@/views/success/success'

Vue.use(VueRouter)

const routes = [
  {
    path: '*',
    name: 'index',
    component: index
  },
  {
    path: '/zhifu',
    name: 'zhifu',
    component: zhifu
  },
  {
    path: '/success',
    name: 'success',
    component: success
  }
]

const router = new VueRouter({
  mode: 'history',
  routes
})

export default router
