<!--
  作者：顾先生
-->
<template>
  <div id="success">
    <!-- LOGO-->
    <div class="logoBox">
      <div class="logoBox_top">
        <img src="../../assets/indexImg/LOGO.png" class="logoBox_Img">
        <div class="logoBox_top_font">让守信的企业获得更多优惠激励</div>
      </div>
      <!-- 公司名称-->
      <div class="companyOne">
        <div ref="companyOne" class="autoCompanyOne">
          <span class="autoCompanyOne_span">{{order.name}}</span>
        </div>
      </div>
      <!-- 符合加入-->
      <div class="ChengLI clearfix">
        <div class="gengduoyiBox_p1">已完成了“守信激励计划”申请</div>
        <div class="gengduoyiBox_p3">获得了<span style="color: #1b8ae0">“守信激励计划惠企服务包”</span>服务</div>
        <div class="gengduoyiBox_p2">为了更好的提升您的服务体验，请您关注以下信息，如有任何问题可以联系我们。</div>
      </div>
    </div>
    <!-- 售前咨询-->
    <div class="ZiXunBox publicOne">
      <div class="ZiXunBoxContent">
        <img src="../../assets/success/lianxi.png" class="ZiXunBoxContent_img">
        <div class="ZiXunBoxContentBox">
          <img src="../../assets/indexImg/boZixun.png" class="ZiXunBoxContentBox_left" @click="boZixun">
          <img src="../../assets/indexImg/weiZixun.png" class="ZiXunBoxContentBox_right" @click="weiZixun">
        </div>
      </div>
    </div>
    <div class="top4Box publicOne">
      <div class="top4">
        <!--    订单信息字体-->
        <div class="order_box">守信激励计划订单信息</div>
        <!--    订单详情-->
        <div class="order_details">
          <p class="order_details_p1">订单编号：{{order.order_no}}</p>
          <p class="order_details_p1">服务内容：{{order.fuwu}}</p>
          <p class="order_details_p1">付款时间：{{order.pay_time}}</p>
          <p class="order_details_p1">支付金额：{{order.user_price}}元</p>
          <p class="order_details_p1">支付方式：{{order.where}}</p>
          <p class="order_details_p1">企业/收货人：{{order.name}}</p>
          <p class="order_details_p1">支付电话/联系电话：{{order.phone}}</p>
          <p class="order_details_p1">牌匾收货地址：{{order.address}}</p>
        </div>
      </div>
    </div>
    <!--    计划保障-->
    <div class="baozhang publicOne">
      <div class="baozhangBox">
        <div class="baozhangBox1">
          <img src="../../assets/success/tixing.png" class="baozhangBox1_img">
          <p class="baozhangBox1_p">特别提醒</p>
        </div>
        <p class="baozhangBox1_p3">荣誉牌匾的审核、制作、邮寄约需10个工作日，但不影响您惠企服务包里面的其他咨询服务享受，您可随时咨询客服享受服务。牌匾的制作进度我们会有短信提醒，如您需要自行查询，可通过以下方法。</p>
      </div>
    </div>
    <!-- 中部第一 -->
    <div class="central">
      <!--  区域3-->
      <div class="top3">
        <div class="styleFont">直接查询法</div>
        <div class="styleFont2">按图示直接点击“查询牌匾进度”可查询当前进度。</div>
        <!--    查询进度-->
        <div class="order_details1Box">
          <img src="../../assets/success/shou.png" class="order_details1Box_position">
          <p class="order_details1Box_p1">【荣誉牌匾进度查询】</p>
          <div class="order_details1Box_p2" @click="chaKan">
            <span class="order_details1Box_span">查看牌匾进度</span>&nbsp;<img src="../../assets/success/sousuo.png" class="order_details1Box_img">
          </div>
        </div>
        <div class="styleFontGuan">关注微信公众号查询</div>
        <div class="styleFont2">建议您用这种方式，因为关注公众号后，您可以随时随地查询牌匾制作进度，不用再通过查找链接来进行牌匾的进度查询。</div>
        <!--    关注公众号-->
        <div class="gongZhong clearfix">
          <p class="gongZhongP1">立即关注“守信企业计划”公众号</p>
          <p class="gongZhongP2">实时查询牌匾当前制作、寄送等进度</p>
          <div class="gongZhongBtn" id="gongZhongBtn">
            <img src="../../assets/success/weixin.png" class="gongZhongBtn_img">&nbsp;
            <span class="gongZhongBtn_span" @click="GuanZhu">立即关注</span>
          </div>
        </div>

        <!--    关注微信公众号方法-->
        <div class="gongZhongFF">
          <img src="../../assets/success/zuoshou.png" class="gongZhongFF_img1">
          <span class="gongZhongFF_span">关注微信公众号的方法</span>
          <img src="../../assets/success/youshou.png" class="gongZhongFF_img1">
        </div>

        <!--    方法一 -->
        <div class="FF1">
          <img src="../../assets/success/diyi.png" class="FF1_img1">
          <span class="FF1_span1">微信搜索-选择【公众号】</span>
        </div>

        <!--    方法1 图片-->
        <div class="Fang1_imgNew">
          <img src="../../assets/success/img1.png" class="Fang1_img1">
        </div>

        <!--    方法二 -->
        <div class="FF1">
          <img src="../../assets/success/dier.png" class="FF1_img1">
          <span class="FF1_span1">输入“守信企业计划”并点击关注公众号</span>
        </div>

        <!--    方法2 图片-->
        <div class="Fang1_imgNew">
          <img src="../../assets/success/img2.png" class="Fang1_img1">
        </div>

        <!--    方法三 -->
        <div class="FF1">
          <img src="../../assets/success/disan.png" class="FF1_img1">
          <span class="FF1_span1">进入公众号点击下方【牌匾进度】</span>
        </div>

        <!--    方法3 图片-->
        <div class="Fang1_imgNew">
          <img src="../../assets/success/img3.png" class="Fang1_img1">
        </div>

        <!--    方法四 -->
        <div class="FF1">
          <img src="../../assets/success/disi.png" class="FF1_img1">
          <span class="FF1_span1">输入要查询牌匾进度的公司名称查询</span>
        </div>

        <!--    方法4 图片-->
        <div class="Fang1_imgNew">
          <img src="../../assets/success/img4.png" class="Fang1_img1">
        </div>

        <!--    关注公众号-->
        <div class="ErWeiMa" id="applyFor">
          <img src="../../assets/success/gongzhonghao.png" class="ErWeiMa_img">
        </div>
        <div class="ErWeiMa_font">
          识别二维码关注“守信企业计划”
        </div>
      </div>
    </div>
    <bottom></bottom>
    <!--    如果用户从微信过来的直接蒙蔽-->
    <div class="WeChatBox" v-if="WeChatBoxIsok"></div>
    <kefu class="kefu_class"></kefu>
  </div>
</template>

<script>
import bottom from '@/components/bottom/bottom'
import kefu from '@/components/kefu/kefu'
import qs from 'qs'
import { chaxun } from '@/http/api/user'
import { Dialog, Toast } from 'vant'
export default {
  name: 'success',
  // 注册组件
  components: { bottom, kefu },
  // 注册方法
  methods: {
    // 拨打客服电话
    boZixun () {
      window.location.href = 'tel://4000642818'
    },
    // 微信客服
    weiZixun () {
      window.location.href = 'https://work.weixin.qq.com/kfid/kfcc327addc3b588368'
    },
    // 公司名称One适配
    companyOneFun () {
      const dom = this.$refs.companyOne
      const lay = dom.offsetWidth
      const span = dom.children[0].offsetWidth
      const average = span / dom.children[0].innerHTML.split('').length
      if (span + average * 1 > lay) {
        const s = (lay - average * 1) / span
        dom.style.transform = `scale(${s})`
      }
    },
    // 查看牌匾进度
    chaKan () {
      // window.location.href = 'https://wechat.zhongqixin360.com/#/'
      Toast('服务器正在升级！抱歉~')
    },
    // 关注公众号
    GuanZhu () {
      this.$el.querySelector('#applyFor').scrollIntoView({
        behavior: 'smooth',
        block: 'start'
      })
    },
    // 订单查询
    async chaxun () {
      const { data } = await chaxun(qs.stringify({
        order_no: localStorage.getItem('orderNo')
      }))
      if (data.order_type === '未支付') {
        this.WeChatBoxIsok = true
        alert('没有支付！请重新从首页进入！')
      }
      this.order = data
    }
  },
  // 父子传值
  props: {},
  // 数据渲染
  data () {
    return {
      order: {},
      WeChatBoxIsok: false
    }
  },
  // 计算属性
  computed: {},
  // 监听器
  watch: {
    companyName (news, jiu) {
      this.$nextTick(() => {
        this.companyOneFun()
      })
    }
  },
  // 页面初次解析完成
  created () {
    // 是否有订单编号
    if (localStorage.getItem('orderNo') !== null && localStorage.getItem('orderNo') !== '') {
      console.log('有订单编号')
    } else {
      this.WeChatBoxIsok = true
      alert('非法访问！无订单编号！如有付款，请联系客服处理！')
    }
  },
  // 页面内容渲染完成
  mounted () {
    // 公司名称适配One
    this.companyOneFun()
    Dialog.confirm({
      title: '支付信息',
      confirmButtonText: '已支付',
      cancelButtonText: '未支付',
      message: '请问您已完成支付了吗'
    })
      .then(() => {
        this.chaxun()
      })
      .catch(() => {
        this.chaxun()
      })
  }
}
</script>

<style scoped lang="less">
// 客服
.kefu_class{
  position: fixed;
  right: 5px;
  bottom: 45px;
}
// 微信蒙层
.WeChatBox{
  position: fixed;
  top: 0;
  width: 100%;
  height: 100vh;
  background-color: #ffffff;
}
#success{
  background-color: #ffce79;
  // LOGO
  .logoBox{
    padding: 15px 15px 0px 15px;
    .logoBox_top{
      display: flex;
      align-items: center;
      justify-content: space-between;
      .logoBox_Img{
        width: 136px;
      }
      .logoBox_top_font{
        margin-left: 5px;
        width: 198px;
        height: 30px;
        line-height: 30px;
        text-align: center;
        background-color: #fb4343;
        border-radius: 10px;
        font-size: 12px;
        font-weight: bolder;
        letter-spacing: 1px;
        color: #ffffff;
      }
    }
    // 公司名称
    .companyOne{
      margin: 15px auto 0px;
      width: 330px;
      .autoCompanyOne{
        width:100%;
        display: flex;
        justify-content: center;
        .autoCompanyOne_span{
          white-space:nowrap;
          font-size: 24px;
          letter-spacing: 2px;
          margin-left: -2px;
          color: #c0040d;
          font-weight: bolder;
        }
      }
    }
    // 符合加入
    .ChengLI{
      margin-top: 15px;
      padding: 20px 25px;
      background-image: url("../../assets/ZhiFuImg/zhifubgc.png");
      background-repeat: no-repeat;
      background-size: 100% 100%;
      background-position: center;
      .gengduoyiBox_p1{
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 18px;
        font-weight: bold;
        letter-spacing: 3px;
        margin-left: 3px;
        color: #333333;
      }
      .gengduoyiBox_p3{
        margin-top: 12px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 13px;
        font-weight: bold;
        letter-spacing: 2px;
        color: #333333;
      }
      .gengduoyiBox_p2{
        text-align: justify;
        margin: 12px auto 0px;
        line-height: 18px;
        font-size: 12px;
        color: #333333;
      }
    }
  }
  // 售前资讯
  .ZiXunBox{
    margin-top: 5px;
    .ZiXunBoxContent{
      height: 70px;
      padding: 10px;
      background-image: url("../../assets/indexImg/zixun.png");
      background-repeat: no-repeat;
      background-size: 100% 100%;
      background-color: #ffffff;
      box-shadow: 0px 2px 5px 0px
      rgba(0, 0, 0, 0.2);
      border-radius: 8px;
      .ZiXunBoxContent_img{
        display: flex;
        width: 80px;
      }
      .ZiXunBoxContentBox{
        margin-top: 10px;
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        .ZiXunBoxContentBox_left,
        .ZiXunBoxContentBox_right{
          width: 123px;
        }
      }
    }
  }
  .top4Box{
    margin-top: 10px;
    // 订单信息
    .top4 {
      margin: 0 auto;
      padding: 5px 0px 8px 0px;
      background-image: url("../../assets/indexImg/gengduo2.png");
      background-repeat: no-repeat;
      background-size: 100% 100%;
      background-position: center;
      box-shadow: 0px 2px 5px 0px
      rgba(0, 0, 0, 0.35);
      border-radius: 8px;
      border: solid 2px #f6aa27;
      .order_box {
        margin: 0 auto 20px;
        width: 90%;
        height: 40px;
        line-height: 40px;
        text-align: center;
        border-bottom: 2px solid #ffce79;
        letter-spacing: 1px;
        font-weight: 900;
        font-size: 17px;
        color: #010101;
      }
      .order_details {
        padding: 0px 20px;
        font-size: 14px;
        color: #000000;
        .order_details_p1 {
          margin-bottom: 10px;
        }
      }
    }
  }
  // 守信计划保障
  .baozhang{
    margin-top: 10px;
    .baozhangBox{
      padding: 10px;
      background-image: url("../../assets/indexImg/zixun.png");
      background-repeat: no-repeat;
      background-size: 100% 100%;
      background-color: #ffffff;
      box-shadow: 0px 2px 5px 0px
      rgba(0, 0, 0, 0.35);
      border-radius: 8px;
      border: solid 2px #f6aa27;
      .baozhangBox1{
        display: flex;
        align-items: center;
        .baozhangBox1_img{
          width: 18px;
          height: 18px;
        }
        .baozhangBox1_p{
          margin-left: 8px;
          font-size: 15px;
          font-weight: bolder;
          letter-spacing: 2px;
          color: #333333;
        }
      }
      .baozhangBox1_p3{
        text-align: justify;
        margin-top: 10px;
        font-size: 12px;
        color: #333333;
      }
    }
  }
  // 主体
  .central {
    width: 345px;
    margin: 10px auto;
    background-image: url("../../assets/indexImg/successgengduo1.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-color: #ffffff;
    box-shadow: 0px 2px 5px 0px
    rgba(0, 0, 0, 0.35);
    border-radius: 8px;
    border: solid 2px #f6aa27;
    .styleFont{
      margin: 12px 0px 0px 12px;
      text-align: center;
      width: 131px;
      height: 35px;
      background-color: #ffb83d;
      border-radius: 8px;
      font-size: 17px;
      font-weight: bold;
      line-height: 35px;
      letter-spacing: 2px;
      color: #333333;
    }
    .styleFontGuan{
      margin: 12px 0px 0px 12px;
      text-align: center;
      width: 200px;
      height: 35px;
      background-color: #ffb83d;
      border-radius: 8px;
      font-size: 17px;
      font-weight: bold;
      line-height: 35px;
      letter-spacing: 2px;
      color: #333333;
    }
    .styleFont2{
      margin: 15px 12px 0px 12px;
      font-size: 12px;
      letter-spacing: 1px;
      color: #333333;
    }
    .top3 {
      width: 100%;
      border-radius: 8px;
      padding-bottom: 10px;
      .order_details1Box {
        position: relative;
        margin: 10px auto 0px;
        width: 90%;
        padding: 10px 0px;
        background: url("../../assets/success/cahxun.png") no-repeat;
        background-size: 100%;
        .order_details1Box_position{
          position: absolute;
          width: 24px;
          bottom: -10px;
          right: 78px;
        }
        .order_details1Box_p1 {
          text-align: center;
          font-size: 18px;
          color: white;
          font-weight: 900;
        }
        .order_details1Box_p2 {
          display: flex;
          align-items: center;
          justify-content: center;
          margin: 5px auto 0px;
          text-align: center;
          width: 150px;
          height: 30px;
          background-color: #ffffff;
          border-radius: 15px;
          color: #f8431b;
          font-size: 15px;
        }
        .order_details1Box_img {
          width: 14px;
          height: 14px;
        }
      }
      .gongZhong {
        width: 90%;
        height: 109px;
        margin: 10px auto 0px;
        background: url("../../assets/success/bgc.png") no-repeat;
        background-size: 100%;
        .gongZhongP1 {
          text-align: center;
          font-weight: bold;
          color: white;
          font-size: 17px;
          margin-top: 10px;
        }
        .gongZhongP2 {
          text-align: center;
          color: white;
          font-size: 13px;
          margin-top: 5px;
        }
        .gongZhongBtn {
          margin: 8px auto 0px;
          width: 160px;
          height: 35px;
          background-color: #fbd8be;
          border-radius: 17px;
          display: flex;
          align-items: center;
          justify-content: center;
          .gongZhongBtn_img {
            width: 22px;
            height: 22px;
            vertical-align: middle;
          }
          .gongZhongBtn_span {
            font-size: 16px;
            font-weight: 900;
            letter-spacing: 1px;
            color: #d80303;
          }
        }
      }
      .gongZhongFF {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 80%;
        height: 20px;
        margin: 19px auto 15px;
        .gongZhongFF_span{
          text-align: center;
          font-size: 20px;
          font-weight: bolder;
          color: #000000;
          margin-left: 5px;
          margin-right: 5px;
        }
        .gongZhongFF_img1{
          width: 13px;
          height: 12px;
        }
      }
      .FF1{
        display: flex;
        align-items: center;
        margin-left: 27px;
        .FF1_img1{
          width: 27px;
          height: 17px;
        }
        .FF1_span1{
          margin-top: 3px;
          margin-left: 14px;
          font-size: 14px;
          font-weight: bolder;
          color: #000000;
        }
      }
      .ErWeiMa {
        width: 150px;
        margin: 10px auto 0px;
      }
      .ErWeiMa_font {
        text-align: center;
        width: 90%;
        height: 25px;
        margin: 0 auto;
        font-size: 16px;
      }
      .ErWeiMa_img {
        width: 100%;
        height: 100%;
      }
      .Fang1_img1 {
        width: 100%;
        height: 100%;
      }
      .Fang1_imgNew{
        width: 90%;
        margin: 10px auto 10px;
      }
    }
  }

}

</style>
