import { render, staticRenderFns } from "./kefu.vue?vue&type=template&id=8ddec558&scoped=true"
import script from "./kefu.vue?vue&type=script&lang=js"
export * from "./kefu.vue?vue&type=script&lang=js"
import style0 from "./kefu.vue?vue&type=style&index=0&id=8ddec558&prod&scoped=true&lang=less"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8ddec558",
  null
  
)

export default component.exports