<!--
  作者：顾先生
-->
<template>
  <div>
    <router-view />
  </div>
</template>

<script>
window.onload = function () {
  document.addEventListener('touchstart', function (event) {
    if (event.touches.length > 1) {
      event.preventDefault()
    }
  })
  document.addEventListener('gesturestart', function (event) {
    event.preventDefault()
  })
}
export default {
  name: 'App',
  // 注册组件
  components: {},
  // 注册方法
  methods: {},
  // 父子传值
  props: {},
  // 数据渲染
  data () {
    return {

    }
  },
  // 计算属性
  computed: {},
  // 监听器
  watch: {},
  // 页面初次解析完成
  created () {
    // eslint-disable-next-line no-new
    // new this.$vconsole()
  },
  // 页面内容渲染完成
  mounted () {}
}
</script>

<style scoped>

</style>
