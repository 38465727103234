<!--
  作者：顾先生
-->
<template>
  <div id="index">
    <!--    主体-->
    <div class="bodyBox">
      <!-- LOGO-->
      <div class="logoBox">
        <div class="logoBox_top">
          <img src="../../assets/indexImg/LOGO.png" class="logoBox_Img">
          <div class="logoBox_top_font">让守信的企业获得更多优惠激励</div>
        </div>
        <!-- 公司名称-->
        <div class="companyOne">
          <div ref="companyOne" class="autoCompanyOne">
            <span class="autoCompanyOne_span">{{ companyName }}</span>
          </div>
        </div>
        <!-- 符合加入-->
        <div class="ChengLI">
          <p class="ChengLI_p1">符合“守信激励计划”加入标准</p>
          <p class="ChengLI_p2">可领取“守信激励”惠企服务</p>
        </div>
      </div>
      <!-- 牌匾图主体-->
      <div class="paibianBox publicOne">
        <div class="paibianBox_bgc">
          <div class="paibianBox_box clearfix">
            <div class="paibianBox_p1"><img src="../../assets/indexImg/gengduo.png" class="paibianBox_p1_img">{{paibianText}}<img src="../../assets/indexImg/gengduo.png" class="paibianBox_p2_img"></div>
          </div>
          <div class="paibianBox_img" @click="tiaoZhuan">
            <img src="../../assets/indexImg/index.png" class="swipe_img">
            <!-- 公司名称-->
            <div class="companyTwo" v-show="swiperFont">
              <div ref="companyTwo" class="autoCompanyTwo">
                <span class="autoCompanyTwo_span">{{ companyName }}</span>
              </div>
            </div>
          </div>
          <van-button class="paibianBox_btn" @click="tiaoZhuan" ref="targetElement">立即领取</van-button>
          <div class="paibianBox_radio">
            <van-checkbox v-model="checked1">同意签署</van-checkbox>
            <p><span style="color: #0075ff" @click="SXShu=true">《守信承诺书》</span>并已阅读“<span style="color: #0075ff" @click="SXshuoMing=true">守信承诺书说明</span>”</p>
          </div>
        </div>
      </div>
      <!-- 售前咨询-->
      <div class="ZiXunBox publicOne">
        <div class="ZiXunBoxContent">
          <img src="../../assets/indexImg/shouqian.png" class="ZiXunBoxContent_img">
          <div class="ZiXunBoxContentBox">
            <img src="../../assets/indexImg/boZixun.png" class="ZiXunBoxContentBox_left" @click="boZixun">
            <img src="../../assets/indexImg/weiZixun.png" class="ZiXunBoxContentBox_right" @click="weiZixun">
          </div>
        </div>
      </div>
      <!-- 更多权益1 -->
      <div class="gengduoyi publicOne">
        <div class="gengduoyiBox clearfix">
          <img src="../../assets/indexImg/quanyi.png" class="quanyi_img">
          <p class="quanyi_font">更多权益一</p>
          <div class="gengduoyiBox_p1"><div class="henggang henggang1"></div>实体荣誉牌匾<div class="henggang henggang2"></div></div>
          <div class="gengduoyiBox_p2"><span style="color:#202ccf;">让上门客户更容易了解企业信用，</span>金属牌匾可悬挂门头，木质牌匾可放于荣誉墙或办公桌。</div>
          <img src="../../assets/indexImg/zuoyong.png" class="zuoyong" @click="tiaoZhuan">
        </div>
      </div>
      <!-- 更多权益2 -->
      <div class="gengduoyi publicOne">
        <div class="gengduoyiBox clearfix">
          <img src="../../assets/indexImg/quanyi.png" class="quanyi_img">
          <p class="quanyi_font">更多权益二</p>
          <div class="gengduoyiBox_p1"><div class="henggang henggang1"></div>更多信用展示<div class="henggang henggang2"></div></div>
          <div class="gengduoyiBox_p2"><span style="color:#202ccf;">多场景展示企业信用形象，让企业信用便于分享和核实。</span>分别在信用网站、电子荣誉证书等多场景展示企业信用。</div>
          <img src="../../assets/indexImg/quanyier.png" class="zuoyong" @click="tiaoZhuan">
        </div>
      </div>
      <!-- 更多权益3 -->
      <div class="gengduoyi publicOne">
        <div class="gengduoyiBox clearfix">
          <img src="../../assets/indexImg/quanyi.png" class="quanyi_img">
          <p class="quanyi_font">更多权益三</p>
          <div class="gengduoyiBox_p1"><div class="henggang henggang1"></div>企业法律咨询<div class="henggang henggang2"></div></div>
          <div class="gengduoyiBox_p2"><span style="color:#202ccf;">解决企业日常经营所遇到的法律疑惑，</span>专业法律团队一对一解决企业日常经营所遇到的常规法律问题、债权管理问题、法律文书不会写等问题。</div>
          <img src="../../assets/indexImg/falvzixun.png" class="zuoyong" @click="tiaoZhuan">
        </div>
      </div>
      <!-- 更多权益4 -->
      <div class="gengduoyi publicOne">
        <div class="gengduoyiBox clearfix">
          <img src="../../assets/indexImg/quanyi.png" class="quanyi_img">
          <p class="quanyi_font">更多权益四</p>
          <div class="gengduoyiBox_p1"><div class="henggang henggang1"></div>商标注册代办<div class="henggang henggang2"></div></div>
          <div class="gengduoyiBox_p2"><span style="color:#202ccf;">解决企业注册商标不专业的问题，</span>专业商标注册团队一对一咨询，解决企业商标注册繁琐、不专业、速度慢等问题。</div>
          <img src="../../assets/indexImg/quanyibiao.png" class="zuoyong" @click="tiaoZhuan">
        </div>
      </div>
      <!-- 介绍 -->
      <div class="jieShao publicOne">
        <div class="jieShaoBox">
          <div class="top"><img src="../../assets/indexImg/jieshao1.png" class="top_img"><p class="top_p">{{AllText.biaoti1}}</p></div>
          <p class="bottom_p publicFont bottomMargin">{{AllText.neirong1}}</p>
          <div class="top"><img src="../../assets/indexImg/jieshao2.png" class="top_img"><p class="top_p">{{AllText.biaoti2}}</p></div>
          <p class="bottom_p publicFont" style="text-indent: 0">{{AllText.neirong2_1}}</p>
          <p class="bottom_p publicFont" style="text-indent: 0">{{AllText.neirong2_2}}</p>
        </div>
      </div>
    </div>
    <!--    底部-->
    <bottom></bottom>
    <!--    守信承诺书-->
    <van-dialog
      v-model="SXShu"
      confirm-button-color="#0075ff"
      confirm-button-text="已阅读"
      message-align="left"
     >
      <p slot="title" class="slotTitle">守信承诺书</p>
      <p slot="default" class="slotdefault1 slotdefault">我单位自愿建立诚实守信经营形象，为了打造良好的诚信营商环境，促进国家信用体系建设，现自愿做出如下承诺：</p>
      <p slot="default" class="slotdefault2 slotdefault">一、坚持诚实守信，积极履行经营者义务和社会责任；</p>
      <p slot="default" class="slotdefault2 slotdefault">二、坚决合法合规经营，不弄虚作假，保障消费者权益；</p>
      <p slot="default" class="slotdefault2 slotdefault">三、自觉接受行业主管部门、市场监管部门和社会公众的监督；</p>
      <p slot="default" class="slotdefault3 slotdefault">单位名称：{{ companyName }}</p>
      <p slot="default" class="slotdefault3 slotdefault slotdefault4">承诺日期：{{ today }}</p>
    </van-dialog>
    <!--    守信承诺书说明-->
    <van-dialog
      v-model="SXshuoMing"
      confirm-button-color="#0075ff"
      confirm-button-text="已阅读"
      message-align="left"
      message="<span>尊敬的企业：</span><span style='text-indent: 2em; text-align:justify;display: inline-block;line-height: 7vw;'>您好！本守信承诺书的签署，仅代表您自愿履行守信经营的意愿，并不承担任何法律责任。签署成功后，企业可加入“守信激励计划”，领取加入守信激励计划后的荣誉牌匾，企业信用展示服务和对应的惠企服务包。</span>">
      <p slot="title" class="slotTitle">守信承诺书说明</p>
    </van-dialog>
    <!--    立即领取悬浮按钮-->
    <Transition>
    <div class="LiJiBtn" v-show="LiJilingquShow" @click="tiaoZhuan">
      <img src="../../assets/indexImg/lingqu.png" class="LiJiBtn_img">
    </div>
    </Transition>
    <!--    占位符-->
    <div class="LiJiBtnZhan" v-show="LiJilingquShow"></div>
    <kefu class="kefu_class"></kefu>
  </div>
</template>

<script>
import bottom from '@/components/bottom/bottom'
import kefu from '@/components/kefu/kefu'
import { companyInfo } from '@/http/api/user'
import qs from 'qs'
import { Toast } from 'vant'
export default {
  name: 'index',
  // 注册组件
  components: { bottom, kefu },
  // 注册方法
  methods: {
    // 拨打客服电话
    boZixun () {
      window.location.href = 'tel://4000642818'
    },
    // 微信客服
    weiZixun () {
      window.location.href = 'https://work.weixin.qq.com/kfid/kfcc327addc3b588368'
    },
    // 获取当前日期
    getCurrentDate () {
      const now = new Date()
      const year = now.getFullYear()
      const month = now.getMonth() + 1
      const day = now.getDate()
      return year + '年' + month + '月' + day + '日'
    },
    // 公司名称One适配
    companyOneFun () {
      const dom = this.$refs.companyOne
      const lay = dom.offsetWidth
      const span = dom.children[0].offsetWidth
      const average = span / dom.children[0].innerHTML.split('').length
      if (span + average * 1 > lay) {
        const s = (lay - average * 1) / span
        dom.style.transform = `scale(${s})`
      }
    },
    // 公司名称Two适配
    companyTwoFun () {
      const dom = this.$refs.companyTwo
      const lay = dom.offsetWidth
      const span = dom.children[0].offsetWidth
      const average = span / dom.children[0].innerHTML.split('').length
      if (span + average * 1 > lay) {
        const s = (lay - average * 1) / span
        dom.style.transform = `scale(${s})`
      }
    },
    // 信息初始
    async companyInfo () {
      const { data } = await companyInfo(qs.stringify({
        random: this.randomNum,
        type: '1'
      }))
      this.comnpany_info = data.comnpany_info
      this.companyName = this.comnpany_info.company
      this.companyImg = data.paibian
    },
    // code获取
    getCode () {
      // 静默授权
      const local = 'https://p8b.cn/zhifu'
      window.location.href = 'https://9y2.cn/test/index1.html?appid=wx2baa0137e94a5509&redirect_uri=' + encodeURIComponent(local) + '&response_type=code&scope=snsapi_base&state=123#wechat_redirect'
    },
    // 点击图片跳转连接
    tiaoZhuan () {
      if (this.checked1) {
        const ua = navigator.userAgent.toLowerCase()
        if (/micromessenger/.test(ua)) {
          this.getCode()
        } else {
          this.$router.push('/zhifu')
        }
      } else {
        Toast('请勾选同意协议')
        window.scrollTo({
          left: 0,
          top: 0,
          behavior: 'smooth'
        })
      }
    },
    // 截取网页随机值
    subUtils (data) {
      const index = data.lastIndexOf('/')
      data = data.substring(index + 1, data.length)
      this.randomNum = data
      localStorage.setItem('RandomNum', this.randomNum)
    }
  },
  // 父子传值
  props: {},
  // 数据渲染
  data () {
    return {
      // 轮播颜色
      LunboColor: 0,
      // 自动轮播
      TimeShiJian: 6000,
      LiJilingquShow: false,
      paibianText: '签署守信承诺书，领取守信激励服务',
      // 轮播图公司名称
      swiperFont: true,
      // 当前日期
      today: '',
      // 牌匾信息图片
      companyImg: {},
      // 随机值
      randomNum: '',
      // 公司信息
      comnpany_info: {},
      // 公司名称适配
      companyName: '',
      // 守信承诺书
      SXShu: false,
      // 守信承诺书说明
      SXshuoMing: false,
      // 同意签署
      checked1: true,
      // 介绍的文案
      AllText: {
        biaoti1: '关于“守信激励计划”',
        neirong1: '“守信激励计划”是由第三方信用机构(中雄世纪征信有限公司)发起，主旨是为有意愿守信经营的中小企业提升企业信用形象，为激励守信意愿提供更多惠企服务的计划，降低中小企业经营成本。',
        biaoti2: '“守信激励计划”加入标准',
        neirong2_1: '1、依法在中国大陆注册的企业并且有意愿守信经营；',
        neirong2_2: '2、成立至今不存在以下行为:失信被执行人记录、被执行人记录、近两年内存在行政处罚记录、存在重大税务违法记录、重大负面舆情、等失信行为。'
      }
    }
  },
  // 计算属性
  computed: {},
  // 监听器
  watch: {
    companyName (news, jiu) {
      this.$nextTick(() => {
        this.companyTwoFun()
        this.companyOneFun()
      })
    }
  },
  // 页面初次解析完成
  created () {
    // 截取随机值 https://p8b.cn/c18 window.location.href
    this.subUtils(window.location.href)
    // 公司信息
    this.companyInfo()
  },
  // 页面内容渲染完成
  mounted () {
    const observer = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          // 元素进入视口
          this.LiJilingquShow = false
        } else {
          // 元素离开视口
          this.LiJilingquShow = true
        }
      })
    })
    observer.observe(this.$refs.targetElement)
    // 公司名称适配One
    this.companyOneFun()
    // 公司名称Two适配
    this.companyTwoFun()
    // 获取当前日期
    this.today = this.getCurrentDate()
  }
}
</script>

<style scoped lang="less">
// 客服
.kefu_class{
  position: fixed;
  right: 0px;
  bottom: 70px;
}
.v-enter-active,
.v-leave-active {
  transform: translateY(100px);
}

.v-enter-from,
.v-leave-to {
  transform: translateY(100px);
}
@keyframes Liji {
  0% {
    transform: scale(1) translate(-50%,-50%);
  }
  25% {
    transform: scale(.95) translate(-50%,-50%);
  }
  100% {
    transform: scale(1) translate(-50%,-50%);
  }
}
.LiJiBtn_img{
  position: absolute;
  left: 50%;
  top: 50%;
  height: 50px;
  transform-origin: 0 0;
  animation: Liji 1s linear infinite;
}
// 占位符
.LiJiBtnZhan{
  height: 70px;
}
// 立即领取悬浮
.LiJiBtn{
  position: fixed;
  bottom: 0;
  z-index: 999;
  width: 100%;
  height: 70px;
  transition: all .5s;
  background-color: #fff;
}
// 守信承诺书标题
.slotTitle{
  font-size: 18px;
  color: #0075ff;
}
// 守信承诺书内容
.slotdefault{
  padding: 4px 24px 4px 24px;
  font-size: 14px;
}
.slotdefault1{
  text-indent: 2em;
  line-height: 22px;
  color: #646566;
}
.slotdefault2,.slotdefault3{
  color: #646566;
}
.slotdefault3{
  text-align: right;
}
.slotdefault4{
  padding-bottom: 20px;
}
// 下边距15px
.bottomMargin{
  margin-bottom: 9px;
}
// border边框 和 圆角
.publicBorder{
  border-radius: 8px;
  border: solid 2.5px #feb191;
}
//主体
.bodyBox{
  // LOGO
  .logoBox{
    padding: 15px 15px 0px 15px;
    height: 202px;
    background-image: url("../../assets/indexImg/topBgc.png");
    background-repeat: no-repeat;
    background-size: 100% 80%;
    .logoBox_top{
      display: flex;
      align-items: center;
      justify-content: space-between;
      .logoBox_Img{
        width: 136px;
      }
      .logoBox_top_font{
        margin-left: 5px;
        width: 198px;
        height: 30px;
        line-height: 30px;
        text-align: center;
        background-color: #fb4343;
        border-radius: 10px;
        font-size: 12px;
        font-weight: bolder;
        letter-spacing: 1px;
        color: #ffffff;
      }
    }
    // 公司名称
    .companyOne{
      margin: 15px auto;
      width: 330px;
      .autoCompanyOne{
        width:100%;
        display: flex;
        justify-content: center;
        .autoCompanyOne_span{
          white-space:nowrap;
          font-size: 24px;
          letter-spacing: 2px;
          margin-left: -2px;
          color: #c0040d;
          font-weight: bolder;
        }
      }
    }
    // 符合加入
    .ChengLI{
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      height: 101px;
      background-image: url("../../assets/indexImg/top_img.png");
      background-repeat: no-repeat;
      background-size: 100% 100%;
      .ChengLI_p1{
        margin-bottom: 5px;
        font-size: 20px;
        font-weight: bold;
        letter-spacing: 2px;
        margin-left: -2px;
        color: #c0040d;
      }
      .ChengLI_p2{
        font-size: 22px;
        font-weight: bold;
        letter-spacing: 2px;
        margin-left: -2px;
        color: #c0040d;
      }
    }
  }
  // 牌匾图主体
  .paibianBox{
    margin-top: 15px;
    .paibianBox_bgc{
      padding-bottom: 12px;
      background-color: #ffffff;
      box-shadow: 0px 2px 5px 0px
      rgba(7, 0, 2, 0.31);
      border-radius: 8px;
      // 牌匾文字区域
      .paibianBox_box{
        text-align: center;
      }
      // 顶部文字1
      .paibianBox_p1{
        margin-top: 12px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 16px;
        font-weight: 900;
        color: #c0040d;
        .paibianBox_p1_img,
        .paibianBox_p2_img{
          width: 21px;
          height: 20px;
        }
        .paibianBox_p1_img{
          margin-right: 9px;
        }
        .paibianBox_p2_img{
          margin-left: 8px;
          transform: rotate(180deg);
        }
      }
      // 图片
      .paibianBox_img{
        position: relative;
        margin: 8px auto;
        width: 327px;
        .swipeRed{
          margin-top: 12px;
          display: flex;
          justify-content: center;
          align-items: center;
          .swipeRed2{
            width: 9px;
            height: 9px;
            background-color: #dedede;
            border-radius: 50%;
          }
          .swipeRed1_active{
            background-color: red !important;
          }
          .swipeRed1{
            margin-left: 5px;
            width: 9px;
            height: 9px;
            background-color: #dedede;
            border-radius: 50%;
          }
        }
        .swipe_img{
          vertical-align: bottom;
          width: 100%;
        }
        .paibianBox_img_Img{
          width: 100%;
        }
        .companyTwo{
          position: absolute;
          left: 131px;
          transform: translateX(-50%);
          top: 56px;
          width: 142px;
          .autoCompanyTwo{
            width:100%;
            display: flex;
            justify-content: center;
            .autoCompanyTwo_span{
              white-space:nowrap;
              font-size: 14px;
              letter-spacing: 2px;
              color: black;
              font-weight: bolder;
            }
          }
        }
      }
      // 单选按钮
      .paibianBox_radio{
        display: flex;
        justify-content: center;
        margin-top: 12px;
        font-size: 13px;
        display: flex;
        /deep/ .van-checkbox__icon{
          font-size: 13px;
        }
        /deep/ .van-checkbox__label{
          margin-left: 4px;
          font-size: 13px;
          line-height: 0;
        }
      }
      // 按钮
      .paibianBox_btn{
        margin: 0 auto;
        display: flex;
        justify-content: center;
        width: 230px;
        height: 40px;
        background-color: #cf2020;
        box-shadow: 0px 4px 2px 0px
        #e0e0e0;
        border-radius: 20px;
        font-size: 20px;
        font-weight: bolder;
        line-height: 40px;
        letter-spacing: 6px;
        color: #ffffff;
      }
    }
  }
  // 售前资讯
  .ZiXunBox{
    margin-top: 15px;
    .ZiXunBoxContent{
      height: 70px;
      padding: 10px;
      background-image: url("../../assets/indexImg/zixun.png");
      background-repeat: no-repeat;
      background-size: 100% 100%;
      background-color: #ffffff;
      box-shadow: 0px 2px 5px 0px
      rgba(0, 0, 0, 0.2);
      border-radius: 8px;
      .ZiXunBoxContent_img{
        display: flex;
        width: 80px;
      }
      .ZiXunBoxContentBox{
        margin-top: 10px;
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        .ZiXunBoxContentBox_left,
        .ZiXunBoxContentBox_right{
          width: 123px;
        }
      }
    }
  }
  // 更多权益一
  .gengduoyi{
    margin-top: 20px;
    .gengduoyiBox{
      position: relative;
      background-image: url("../../assets/indexImg/gengduo1.png");
      background-repeat: no-repeat;
      background-size: 100% 100%;
      background-color: #fff;
      padding-bottom: 12px;
      box-shadow: 0px 2px 5px 0px
      rgba(0, 0, 0, 0.2);
      border-radius: 8px;
      .quanyi_img{
        position: absolute;
        top: -6px;
        left: 50%;
        transform: translateX(-50%);
        width: 193px;
      }
      .quanyi_font{
        position: absolute;
        top: -2px;
        left: 50%;
        transform: translateX(-50%);
        z-index: 1;
        font-size: 18px;
        font-weight: bold;
        letter-spacing: 2px;
        margin-left: 2px;
        color: #000000;
      }
      .zuoyong{
        display: block;
        width: 320px;
        margin: 0 auto;
      }
      .gengduoyiBox_p2{
        margin: 15px auto;
        width: 302px;
        font-size: 12px;
        font-weight: bold;
        letter-spacing: 2px;
        color: #333333;
      }
      .gengduoyiBox_p1{
        margin-top: 56px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 16px;
        font-weight: bold;
        letter-spacing: 2px;
        color: #cf2020;
        .henggang{
          width: 40px;
          height: 3px;
          background-color: #cf2020;
        }
        .henggang2{
          margin-left: 9px;
        }
        .henggang1{
          margin-right: 11px;
        }
      }
    }
  }
  // 介绍-文案
  .jieShao{
    margin-top: 15px;
    .jieShaoBox{
      padding: 15px;
      background-image: url("../../assets/indexImg/gengduo1.png");
      background-repeat: no-repeat;
      background-size: 100% 100%;
      background-color: #ffffff;
      box-shadow: 0px 2px 5px 0px
      rgba(7, 0, 2, 0.31);
      border-radius: 8px;
      .top{
        display: flex;
        align-items: center;
        margin-bottom: 10px;
        .top_img{
          width: 15px;
          height: 15px;
          margin-right: 8px;
        }
        .top_p{
          font-size: 18px;
          font-weight: bolder;
          color: #d65c1c;
        }
      }
      .bottom_p{
        text-indent: 1em;
        font-size: 12px;
        line-height: 20px;
        letter-spacing: 1px;
        color: #333333;
      }
    }
  }
}
</style>
